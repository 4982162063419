import React from 'react';
const SvgInvisible = ({color, width = 24, height = 24, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        {...props}
    >
        <g transform={`scale(${width / 24})`}>
            <path
                fill={color}
                d="M12 7.9c2.1 0 3.8 1.7 3.8 3.8 0 .5-.1.9-.3 1.4l2.2 2.2c1.1-.9 2-2.2 2.6-3.6C19 8.4 15.8 6.1 12 6.1c-1 0-2.1.2-3 .5l1.6 1.6c.5-.2.9-.3 1.4-.3M4.5 5.8l1.7 1.7.3.3c-1.2 1-2.2 2.3-2.8 3.7 1.3 3.3 4.5 5.6 8.2 5.6q1.8 0 3.3-.6l.3.3 2.2 2.2 1-1L5.5 4.9zM8.7 10l1.2 1.2c0 .2-.1.3-.1.5 0 1.2 1 2.2 2.2 2.2.2 0 .3 0 .5-.1l1.2 1.2c-.5.2-1.1.4-1.6.4-2.1 0-3.8-1.7-3.8-3.8 0-.6.1-1.1.4-1.6m3.2-.6 2.4 2.4v-.1c0-1.2-1-2.2-2.2-2.2z"
            />
        </g>
    </svg>
);
export default SvgInvisible;
