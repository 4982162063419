
import React from 'react';

const SvgCompany = ({color, ...props}) => (
    <svg 
        xmlns="http://www.w3.org/2000/svg"
        width="24" 
        height="24" 
        viewBox="0 0 24 24" 
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M12 8.29999V5.29999H4.5V18.8H19.5V8.29999H12ZM7.5 17.3H6V15.8H7.5V17.3ZM7.5 14.3H6V12.8H7.5V14.3ZM7.5 11.3H6V9.79999H7.5V11.3ZM7.5 8.29999H6V6.79999H7.5V8.29999ZM10.5 17.3H9V15.8H10.5V17.3ZM10.5 14.3H9V12.8H10.5V14.3ZM10.5 11.3H9V9.79999H10.5V11.3ZM10.5 8.29999H9V6.79999H10.5V8.29999ZM18 17.3H12V15.8H13.5V14.3H12V12.8H13.5V11.3H12V9.79999H18V17.3ZM16.5 11.3H15V12.8H16.5V11.3ZM16.5 14.3H15V15.8H16.5V14.3Z"
        />
    </svg>
);

export default SvgCompany;
