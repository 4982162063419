import React from 'react';

const SvgMigrate = ({color, width = 24, height = 24, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        {...props}
    >
        <g transform={`scale(${width / 24})`}>
            <path
                d="M9.25 18.75C7.7875 18.75 6.54688 18.2406 5.52813 17.2219C4.50938 16.2031 4 14.9625 4 13.5C4 12.1625 4.44063 10.9969 5.32188 10.0031C6.20312 9.00938 7.30625 8.4375 8.63125 8.2875L7.45 7.05L8.5 6L11.5 9L8.5 12L7.45 10.9313L8.55625 9.825C7.66875 10 6.9375 10.4313 6.3625 11.1188C5.7875 11.8063 5.5 12.6 5.5 13.5C5.5 14.5375 5.86563 15.4219 6.59688 16.1531C7.32813 16.8844 8.2125 17.25 9.25 17.25H11.5V18.75H9.25ZM13 12V6.75H19.75V12H13ZM13 18.75V13.5H19.75V18.75H13ZM14.5 17.25H18.25V15H14.5V17.25Z"
                fill={color} />
        </g>
    </svg>);

export default SvgMigrate;
