import React from 'react';

const SvgQuestion = ({color, width = 24, height = 24, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        {...props}
    >
        <g clipPath="url(#clip0_245_12)" transform={`scale(${width / 24})`}>
            <path
                d="M11.25 16.5H12.75V15H11.25V16.5ZM12 4.5C7.86 4.5 4.5 7.86 4.5 12C4.5 16.14 7.86 19.5 12 19.5C16.14 19.5 19.5 16.14 19.5 12C19.5 7.86 16.14 4.5 12 4.5ZM12 18C8.6925 18 6 15.3075 6 12C6 8.6925 8.6925 6 12 6C15.3075 6 18 8.6925 18 12C18 15.3075 15.3075 18 12 18ZM12 7.5C10.3425 7.5 9 8.8425 9 10.5H10.5C10.5 9.675 11.175 9 12 9C12.825 9 13.5 9.675 13.5 10.5C13.5 12 11.25 11.8125 11.25 14.25H12.75C12.75 12.5625 15 12.375 15 10.5C15 8.8425 13.6575 7.5 12 7.5Z"
                fill={color} />
        </g>
        <defs>
            <clipPath id="clip0_245_12">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default SvgQuestion;
