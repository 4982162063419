import React from 'react';

const SvgTextSize = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M13.5 18V8.25H9.75V6h9.75v2.25h-3.75V18H13.5Zm-6.75 0v-6H4.5V9.75h6.75V12H9v6H6.75Z"
        />
    </svg>
);

export default SvgTextSize;
