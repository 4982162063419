import React from 'react';

const SvgUnsubscribed = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            fillRule="evenodd"
            d="M18 6c.825 0 1.5.675 1.5 1.5V11H18V9l-6 3.75L6 9v7.5h7V18H6c-.825 0-1.5-.675-1.5-1.5v-9C4.5 6.675 5.175 6 6 6h12Zm-6 5.242L18 7.5H6l6 3.742Z"
            clipRule="evenodd"
        />
        <path
            fill={color}
            d="m17 13.943 1.567-1.568 1.058 1.057L18.065 15l1.56 1.567-1.058 1.058L17 16.065l-1.568 1.56-1.057-1.058L15.943 15l-1.568-1.568 1.057-1.057L17 13.943Z"
        />
    </svg>
);

export default SvgUnsubscribed;
