import React from 'react';
import Icon from 'MAINAPPJS/components/icons/icon/icon';
import styles from '../styles/index.less';

const PostSubmissionPage = ({action}) => {

    if (action.url_to_redirect) {
        // eslint-disable-next-line scanjs-rules/assign_to_href
        window.top.location.href = action.url_to_redirect;
        return null;
    }

    return (
        <>
            <style>{styles.toString()}</style>
            <div className="info-page __with-form-styles">
                <div className="info-page-gradient-box-wrapper">
                    <div className="info-page-gradient-box __success">
                        <Icon iconValue="rounded-complete" color="#FFFFFF" />
                    </div>
                </div>
                <h2 className="info-page-title">{action.title}</h2>
                {!!action.message && <p className="info-page-message">{action.message}</p>}
                <p className="info-page-cta-link">Create your own <a href="https://www.nimble.com/convert-with-web-forms" target="_blank" rel="noopener noreferrer">Web Forms with Nimble</a> today</p>
            </div>
        </>
    );
};

export default PostSubmissionPage;
