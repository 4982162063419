import React from 'react';

const SvgUndo = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path fill={color} d="M7.25 17.25V15.75H12.575C13.3625 15.75 14.0469 15.5 14.6281 15C15.2094 14.5 15.5 13.875 15.5 13.125C15.5 12.375 15.2094 11.75 14.6281 11.25C14.0469 10.75 13.3625 10.5 12.575 10.5H7.85L9.8 12.45L8.75 13.5L5 9.75L8.75 6L9.8 7.05L7.85 9H12.575C13.7875 9 14.8281 9.39375 15.6969 10.1813C16.5656 10.9688 17 11.95 17 13.125C17 14.3 16.5656 15.2812 15.6969 16.0688C14.8281 16.8563 13.7875 17.25 12.575 17.25H7.25Z" />
    </svg>
);

export default SvgUndo;
