import React from 'react';

const SvgSettings = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M17.6 12.7c0-.2.1-.5.1-.7 0-.2 0-.5-.1-.7l1.6-1.2c.1-.1.2-.3.1-.5L17.8 7c-.1-.2-.3-.2-.5-.2l-1.9.8c-.4-.3-.8-.5-1.3-.7l-.3-2c0-.2-.2-.3-.4-.3h-3c-.2 0-.3.1-.4.3l-.3 2c-.5.2-.9.4-1.3.7l-1.9-.8c-.2-.1-.4 0-.5.2L4.5 9.6c-.1.2-.1.4.1.5l1.6 1.2c0 .2-.1.5-.1.7 0 .2 0 .5.1.7l-1.6 1.2c-.1.1-.2.3-.1.5L6 17c.1.2.3.2.5.2l1.9-.8c.4.3.8.5 1.3.7l.3 2c0 .2.2.3.4.3h3c.2 0 .3-.1.4-.3l.3-2c.5-.2.9-.4 1.3-.7l1.9.8c.2.1.4 0 .5-.2l1.5-2.6c.1-.2.1-.4-.1-.5l-1.6-1.2ZM12 14.6c-1.4 0-2.6-1.2-2.6-2.6 0-1.4 1.2-2.6 2.6-2.6 1.4 0 2.6 1.2 2.6 2.6 0 1.4-1.2 2.6-2.6 2.6Z"
        />
    </svg>
);

export default SvgSettings;
