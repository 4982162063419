import React from 'react';

const SvgTag = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M4.5 12.8c0 .4.2.8.4 1.1l5.2 5.2c.3.3.6.4 1.1.4.5 0 .8-.2 1.1-.4l6.8-6.8c.3-.3.4-.7.4-1.1V6c0-.8-.7-1.5-1.5-1.5h-5.3c-.4 0-.8.2-1.1.4l-6.8 6.8c-.1.3-.3.6-.3 1.1Zm11.3-5.7c0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1-.6 0-1.1-.5-1.1-1.1Z"
        />
    </svg>
);

export default SvgTag;
