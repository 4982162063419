export const COMPONENTS_PROPS_KEYS = {
    logoColorVariable: 'logoColorVariable',
    logoAlignment: 'logoAlignment',
    theme: 'theme',
    buttonType: 'buttonType',
    buttonLabel: 'buttonLabel',
    presetValue: 'presetValue',
    fieldSize: 'fieldSize',
    fieldLabelPos: 'fieldLabelPos',
    fieldWithIcons: 'fieldWithIcons',
    fieldBorderType: 'fieldBorderType',
    buttonForm: 'buttonForm',
};
