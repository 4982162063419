import React from 'react';

const SvgRibbon = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M15.8 5.3H8.3c-.8 0-1.5.7-1.5 1.5v12l5.2-2.2 5.2 2.2v-12c.1-.8-.6-1.5-1.4-1.5Z"
        />
    </svg>
);

export default SvgRibbon;
