import React from 'react';

const SvgTablet = ({color, width = 24, height = 24, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            transform={`scale(${width / 24})`}
            d="M11.75 18.625c.213 0 .39-.072.534-.216a.726.726 0 0 0 .216-.534.726.726 0 0 0-.216-.534.726.726 0 0 0-.534-.216.726.726 0 0 0-.534.216.726.726 0 0 0-.216.534c0 .212.072.39.216.534a.726.726 0 0 0 .534.216ZM6.5 20.5c-.412 0-.766-.147-1.06-.44A1.445 1.445 0 0 1 5 19V5.5c0-.412.147-.766.44-1.06.294-.293.647-.44 1.06-.44H17c.413 0 .766.147 1.06.44.293.294.44.647.44 1.06V19c0 .413-.147.766-.44 1.06-.294.293-.647.44-1.06.44H6.5Zm0-3.75V19H17v-2.25H6.5Zm0-1.5H17v-7.5H6.5v7.5Zm0-9H17V5.5H6.5v.75Z"
        />
    </svg>
);

export default SvgTablet;
