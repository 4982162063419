import React from 'react';
import styles from '../styles/index.less';

const LoaderPage = () => (
    <>
        <style>{styles.toString()}</style>
        <div className="__loading" />
    </>
);

export default LoaderPage;
