import React from 'react';

const SvgReplyAll = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M8.3 9.1V6.9L3 12.1l5.3 5.2v-2.2l-3-3 3-3Zm4.5.8v-3l-5.3 5.2 5.3 5.2v-3.1c3.8 0 6.4 1.2 8.2 3.8-.7-3.6-3-7.4-8.2-8.1Z"
        />
    </svg>
);

export default SvgReplyAll;
