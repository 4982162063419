import React from 'react';

const SvgNote = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M13.5 15.8H6v1.5h7.5v-1.5Zm4.5-6H6v1.5h12V9.8ZM6 14.2h12v-1.5H6v1.5Zm0-7.4v1.5h12V6.8H6Z"
        />
    </svg>
);

export default SvgNote;
