import React from 'react';

const SvgContacts = ({color, width = 24, height = 24,...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        {...props}
    >
        <g transform={`scale(${width / 24})`}>
            <path
                fill={color}
                d="M15 11.2c1.2 0 2.2-1 2.2-2.2 0-1.2-1-2.2-2.2-2.2-1.2 0-2.2 1-2.2 2.2 0 1.2 1 2.2 2.2 2.2Zm-6 0c1.2 0 2.2-1 2.2-2.2 0-1.2-1-2.2-2.2-2.2-1.2 0-2.2 1-2.2 2.2 0 1.2 1 2.2 2.2 2.2Zm0 1.6c-1.7 0-5.2.9-5.2 2.6v1.9h10.5v-1.9c0-1.8-3.6-2.6-5.3-2.6Zm6 0h-.7c.9.6 1.5 1.5 1.5 2.6v1.9h4.5v-1.9c-.1-1.8-3.6-2.6-5.3-2.6Z"
            />
        </g>
    </svg>
);

export default SvgContacts;
