import React from 'react';

const SvgPerson = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M9.8 11.9c-.5 0-.9.4-.9.9s.4.9.9.9.9-.4.9-.9-.4-.9-.9-.9Zm4.5 0c-.5 0-.9.4-.9.9s.4.9.9.9.9-.4.9-.9-.4-.9-.9-.9ZM12 4.5c-4.1 0-7.5 3.4-7.5 7.5s3.4 7.5 7.5 7.5 7.5-3.4 7.5-7.5-3.4-7.5-7.5-7.5ZM12 18c-3.3 0-6-2.7-6-6v-.6c1.8-.8 3.2-2.2 3.9-4 1.4 1.9 3.6 3.2 6.1 3.2.6 0 1.1-.1 1.7-.2.2.5.2 1.1.2 1.7.1 3.3-2.6 5.9-5.9 5.9Z"
        />
    </svg>
);

export default SvgPerson;
