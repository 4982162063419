import React from 'react';

const SvgRightAngleBracket = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path fill={color} d="m8.6 16.6 4.6-4.6-4.6-4.6L10 6l6 6-6 6-1.4-1.4Z" />
    </svg>
);

export default SvgRightAngleBracket;
