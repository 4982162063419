import React from 'react';

/**
 * SVG icon with customizable color.
 * @param {object} props - The component props.
 * @param {string} props.color - The color of the SVG icon.
 * @returns {JSX.Element} Rendered SVG icon.
 */
const SvgInfo = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={30}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M15 5.625c-5.125 0-9.375 4.25-9.375 9.375s4.25 9.375 9.375 9.375 9.375-4.25 9.375-9.375S20.125 5.625 15 5.625Zm1 14.125h-1.875v-5.625H16v5.625Zm0-7.5h-1.875v-1.875H16v1.875Z"
        />
    </svg>
);

export default SvgInfo;
