import React from 'react';

const SvgWarning = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path d="M3.79999 17.6H20.3L12.1 3.40002L3.79999 17.6ZM12.8 15.4H11.3V13.9H12.8V15.4ZM12.8 12.4H11.3V9.40002H12.8V12.4Z" fill={color}/>    
    </svg>
);

export default SvgWarning;