import React from 'react';

const SvgWorkflow = ({color, width = 24, height = 24, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        {...props}
    >
        <g clipPath="url(#clip0_1752_189)" transform={`scale(${width / 24})`}>
            <path d="M18 3H6V4.5H18V3ZM6 21H18V19.5H6V21ZM18 6H6C5.175 6 4.5 6.675 4.5 7.5V16.5C4.5 17.325 5.175 18 6 18H18C18.825 18 19.5 17.325 19.5 16.5V7.5C19.5 6.675 18.825 6 18 6ZM12 8.0625C12.93 8.0625 13.6875 8.82 13.6875 9.75C13.6875 10.68 12.93 11.4375 12 11.4375C11.07 11.4375 10.3125 10.68 10.3125 9.75C10.3125 8.82 11.07 8.0625 12 8.0625ZM15.75 15.75H8.25V14.625C8.25 13.3725 10.7475 12.75 12 12.75C13.2525 12.75 15.75 13.3725 15.75 14.625V15.75Z" fill={color}/>
        </g>
        <defs>
            <clipPath id="clip0_1752_189">
                <rect width="24" height="24" fill={color}/>
            </clipPath>
        </defs>   
    </svg>
);

export default SvgWorkflow;