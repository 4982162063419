/**
 * Creates a collection of strings that can be rendered and extended.
 * Primary use case is creating css classes for nested dom elements, hence the naming.
 *
 * Example:
 * Suppose there exists a `headed-box` element that represents a box with a header. Obviously, this element has
 * a header inside it. Suppose further that inside the header there is a title. We want each element to have a class
 * that is prefixed by its parents class, so each element gets the following classes: `headed-box` for the root element,
 * `headed-box-header` for the header and `headed-box-header-title` for the title. Suppose further that we want to use
 * headed box as a container for a listing on the settings page. We want to be able to alter the look of the box when
 * it is used as a listing container so we add a className prefixed with settings-listing to all its elements.
 * Now we have the following classes:
 * `headed-box settings-listing` for the root element,
 * `headed-box-header settings-listing-header` for the header and
 * `headed-box-header-title settings-listing-header-title` for the title.
 * There is a lot of repetition in these classNames. This repetition can be removed using the "class chain" with
 * `headed-box` and `settings-listing` as base classes. The classNames would then be specified in a following way:
 * `headed-box settings-listing` -> `base := createClassChain(['headed-box', 'settings-listing'])`,
 * `headed-box-header settings-listing-header` -> `base.extend('header') =: headerBase`
 * `headed-box-header-title settings-listing-header-title` -> `headerBase.extend('title')`
 * @param baseClasses - starting members of the collection of classes
 */

export const createClassChain = (baseClasses) => ({
    /**
     * Derives a new collection from the existing one.
     * Derived collection will contain all classes that are a combination of one base class and one new class
     * Classes are combined the following way: `${baseClass}-${newClass}` if `newClass` is not an empty string.
     * If `newClass` is an empty string then the result of a combination is `baseClass`
     * @param {string | string[]} classNames - collection of classes that will be used to extend current classes
     * @returns {{extend, extendNew, toString}} - return this
     */
    extend(classNames){
        let newBase;

        if (typeof classNames === 'string') {
            newBase = createChainLink(baseClasses, classNames);
        } else if (Array.isArray(classNames)) {
            newBase = createChainLinks(baseClasses, classNames);
        } else {
            throw 'Class chain can only be extended by strings and arrays of strings';
        }

        return createClassChain(newBase);
    },
    /**
     * Derives a new collection from the existing one.
     * Derived collection will contain all the classes from the previous collection as well as classes of the form
     * baseClass-className
     * @param {string} className - class name
     * @returns {*|{extend, extendNew, toString}} - return this
     */
    extendNew(className){
        return this.extend(['', className]);
    },
    /**
     * Create a string that contains all classes from the collection separated by spaces
     * @returns {string} - return class name
     */
    toString: () => baseClasses.join(' ')
});

const createChainLink = (baseClasses, className) => (
    className
        ? baseClasses.map((baseClassName) => `${baseClassName}-${className}`)
        : baseClasses
);

const createChainLinks = (baseClasses, classNames) => (
    classNames.reduce(
        (base, className) => base.concat(createChainLink(baseClasses, className)),
        []
    )
);

/**
 * Composes a class name string based on conditional and unconditional classes.
 * @param {Record<string, boolean>} conditionsByClass - An object where the keys are class names and the values are conditions.
 * @param {...string} unconditionalClasses - Additional class names that are always included.
 * @returns {string} - A single string of class names.
 * @example
 * composeClassName({
 *   'active': true,
 *   'disabled': false
 * },'btn', 'btn-primary');
 *
 * -->'btn btn-primary active'
 */
export const composeClassName = (conditionsByClass, ...unconditionalClasses) => (
    Object
        .keys(conditionsByClass)
        .filter((className) => conditionsByClass[className])
        .concat(unconditionalClasses)
        .join(' ')
);

export const getPositionClassName = (position) => position.split(' ').map((pos) => `__pos-${pos}`).join(' ');
