import React from 'react';

const SvgMessage = ({color, width = 24, height = 24, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        {...props}
    >
        <g transform={`scale(${width / 24})`}>
            <path
                fill={color}
                d="M18 6H6c-.8 0-1.5.7-1.5 1.5v9c0 .8.7 1.5 1.5 1.5h12c.8 0 1.5-.7 1.5-1.5v-9c0-.8-.7-1.5-1.5-1.5Zm0 3-6 3.8L6 9V7.5l6 3.8 6-3.8V9Z"
            />
        </g>
    </svg>
);

export default SvgMessage;
