import React, {useRef, useState, useLayoutEffect} from 'react';
import Icon from 'MAINAPPJS/components/icons/icon/icon';
import {
    BORDER_TYPE_CLASS_NAMES,
    FIELD_LABEL_POSITIONS,
    LABEL_POSITION_CLASS_NAMES,
} from 'WEBFORMS/config';

const MIN_HEIGHT = 80;
const MAX_HEIGHT = 140;

const MultiLineField = ({
    name,
    id,
    label,
    isRequired,
    icon,
    instantlyShowErrorOnInvalidation = false,
    predefinedValue,
    labelPos,
    helpText,
    borderType,
    placeholder,
    disabled = false,
    setLabelRef,
}) => {
    const [value, setValue] = useState(predefinedValue || '');
    const [isFocused, setIsFocused] = useState(false);
    const textareaRef = useRef(null);

    useLayoutEffect(() => {
        setValue(predefinedValue || '');
        if (predefinedValue && textareaRef.current) {
            textareaRef.current.style.height = getHeight(textareaRef.current.scrollHeight);
        }
    }, [predefinedValue]);

    const onFocus = () => setIsFocused(true);

    const onBlur = () => setIsFocused(false);

    const getHeight = (scrollHeight) => {
        if (scrollHeight <= MIN_HEIGHT) {
            return `${MIN_HEIGHT}px`;
        }

        if (scrollHeight >= MAX_HEIGHT) {
            return `${MAX_HEIGHT}px`;
        }

        return `${scrollHeight}px`;
    };

    const onChange = (e) => {
        setValue(e.target.value);

        textareaRef.current.style.height = '0';
        const scrollHeight = e.target.scrollHeight;
        textareaRef.current.style.height = getHeight(scrollHeight);
    };

    const classNames = ['multiline-field', 'field-box', LABEL_POSITION_CLASS_NAMES[labelPos]];
    isFocused && classNames.push('__focused');
    !!value.trim() && classNames.push('__filled');
    !!icon && classNames.push('__with-icon');
    instantlyShowErrorOnInvalidation && classNames.push('was-validated');
    placeholder && classNames.push('__with-placeholder');
    disabled && classNames.push('__disabled');

    const labelClassNames = ['multiline-field-label'];
    if (placeholder && labelPos === FIELD_LABEL_POSITIONS.inside) {
        labelClassNames.push('__active');
    }

    const errorMsg = 'This field is required';

    const iconJSX = !!icon && <Icon iconValue={icon} className="input-icon" />;

    const isEmpty = !value.trim().length;
    const isInvalid = isRequired && isEmpty;
    const tabIndex = disabled ? -1 : 1;

    return (
        <div className={classNames.join(' ')}>
            <label htmlFor={id} className={labelClassNames.join(' ')} ref={setLabelRef} data-required={isRequired} title={label}>
                {labelPos === 'left' && iconJSX}
                <span>{label}</span>
            </label>
            <div className="multiline-field-input-wrapper">
                {labelPos !== 'left' && iconJSX}
                <textarea
                    name={name}
                    id={id}
                    ref={textareaRef}
                    value={value}
                    className={`multiline-field-input ${BORDER_TYPE_CLASS_NAMES[borderType]}`}
                    aria-invalid={isInvalid}
                    data-empty={isEmpty}
                    data-invalid={isInvalid}
                    data-required={isRequired}
                    placeholder={placeholder}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    tabIndex={tabIndex}
                    aria-errormessage={`error-msg-${id}`}
                    aria-describedby={helpText ? `help-text-${id}` : ''}
                />
                <span className="required-label-placeholder" />
                <div className="messages">
                    <p className="error-msg" id={`error-msg-${id}`} title={errorMsg}>{errorMsg}</p>
                    {!!helpText && <p className="help-text" id={`help-text-${id}`} title={helpText}>{helpText}</p>}
                </div>
            </div>
        </div>
    );
};

export default MultiLineField;
