import React from 'react';

const SvgStop = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        {...props}
    >
        <g opacity={0.5}>
            <rect
                width={18}
                height={18}
                x={1}
                y={1}
                stroke={color}
                strokeWidth={2}
                rx={9}
            />
            <path fill={color} d="m17.294 4.634 1 1.732-15.589 9-1-1.732z" />
        </g>
    </svg>
);

export default SvgStop;
