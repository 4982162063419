import React from 'react';

const SvgCourthouse = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <g fill={color} clipPath="url(#courthouse_svg__a)">
            <path d="M7.75 10.75H5.5V16h2.25v-5.25ZM12.625 10.75h-2.25V16h2.25v-5.25ZM19 17.5H4v2.25h15V17.5ZM17.5 10.75h-2.25V16h2.25v-5.25ZM11.5 4 4 7.75v1.5h15v-1.5L11.5 4Z" />
        </g>
        <defs>
            <clipPath id="courthouse_svg__a">
                <path fill="#fff" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default SvgCourthouse;
