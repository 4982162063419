import React from 'react';

const SvgCup = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M18.2 5.3h-12v7.5c0 1.7 1.3 3 3 3h4.5c1.7 0 3-1.3 3-3v-2.2h1.5c.8 0 1.5-.7 1.5-1.5V6.9c0-.9-.6-1.6-1.5-1.6Zm0 3.7h-1.5V6.8h1.5V9Zm-12 8.3h12v1.5h-12v-1.5Z"
        />
    </svg>
);

export default SvgCup;
