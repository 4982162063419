import React from 'react';

const SvgProtection = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M15.195 15.947c.323 0 .598-.119.825-.356a1.18 1.18 0 0 0 .34-.842c0-.323-.114-.598-.34-.825a1.123 1.123 0 0 0-.825-.34c-.324 0-.604.114-.842.34a1.097 1.097 0 0 0-.356.825c0 .324.119.604.356.842.238.237.518.356.842.356Zm-.026 2.344c.418 0 .797-.088 1.134-.263.338-.175.625-.425.863-.75a4.558 4.558 0 0 0-.975-.394 3.987 3.987 0 0 0-3 .394c.237.325.521.575.853.75.331.175.706.263 1.125.263Zm.056 1.218c-.969 0-1.797-.346-2.484-1.04-.688-.694-1.032-1.516-1.032-2.466 0-.98.344-1.816 1.031-2.507a3.388 3.388 0 0 1 2.494-1.037c.963 0 1.791.346 2.485 1.037a3.409 3.409 0 0 1 1.04 2.507c0 .95-.346 1.772-1.04 2.466-.694.694-1.525 1.04-2.494 1.04Zm-3.984 0c-1.725-.4-3.157-1.378-4.294-2.934-1.138-1.556-1.706-3.34-1.706-5.353V6.74l6-2.25 6 2.25v5.062a4.89 4.89 0 0 0-.975-.347 4.388 4.388 0 0 0-1.032-.122c-1.284 0-2.38.456-3.288 1.368-.908.911-1.362 2.012-1.362 3.301 0 .6.122 1.188.366 1.763a4.682 4.682 0 0 0 1.003 1.5c-.112.062-.231.109-.356.14a4.814 4.814 0 0 0-.356.103Z"
        />
    </svg>
);

export default SvgProtection;
