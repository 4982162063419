import React from 'react';

const SvgReply = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M10.5 9.4v-3l-5.2 5.2 5.2 5.2v-3.1c3.8 0 6.4 1.2 8.2 3.8-.7-3.6-2.9-7.4-8.2-8.1Z"
        />
    </svg>
);

export default SvgReply;
