import React from 'react';

const SvgTrash = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            d="M7.50002 17.2C7.50002 18 8.20002 18.7 9.00002 18.7H15C15.8 18.7 16.5 18 16.5 17.2V9H7.50002V17.2ZM17.3 6H14.7L13.9 5.20001H10.1L9.30002 6H6.70001V7.5H17.2V6H17.3Z"
            fill={color}
            data-testid="trash-icon-path"
        />
    </svg>
);

export default SvgTrash;