import React from 'react';

const SvgToggle = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path d="M8.25 16.5C7 16.5 5.9375 16.0625 5.0625 15.1875C4.1875 14.3125 3.75 13.25 3.75 12C3.75 10.75 4.1875 9.6875 5.0625 8.8125C5.9375 7.9375 7 7.5 8.25 7.5H15.75C17 7.5 18.0625 7.9375 18.9375 8.8125C19.8125 9.6875 20.25 10.75 20.25 12C20.25 13.25 19.8125 14.3125 18.9375 15.1875C18.0625 16.0625 17 16.5 15.75 16.5H8.25ZM8.25 15.375H15.75C16.6875 15.375 17.4844 15.0469 18.1406 14.3906C18.7969 13.7344 19.125 12.9375 19.125 12C19.125 11.0625 18.7969 10.2656 18.1406 9.60938C17.4844 8.95312 16.6875 8.625 15.75 8.625H8.25C7.3125 8.625 6.51562 8.95312 5.85938 9.60938C5.20312 10.2656 4.875 11.0625 4.875 12C4.875 12.9375 5.20312 13.7344 5.85938 14.3906C6.51562 15.0469 7.3125 15.375 8.25 15.375ZM8.23125 13.8937C8.75625 13.8937 9.20312 13.7094 9.57187 13.3406C9.94062 12.9719 10.125 12.525 10.125 12C10.125 11.475 9.94062 11.0281 9.57187 10.6594C9.20312 10.2906 8.75625 10.1063 8.23125 10.1063C7.70625 10.1063 7.25938 10.2906 6.89062 10.6594C6.52187 11.0281 6.3375 11.475 6.3375 12C6.3375 12.525 6.52187 12.9719 6.89062 13.3406C7.25938 13.7094 7.70625 13.8937 8.23125 13.8937Z" fill={color}/>
    </svg>

);

export default SvgToggle;