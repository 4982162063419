import React from 'react';

const SvgCheckedList = ({color, width = 24, height = 24, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        {...props}
    >
        <g transform={`scale(${width / 24})`}>
            <path
                d="M4.125 14.25V12.75H5.625V14.25H4.125ZM4.125 11.25V9.75H5.625V11.25H4.125ZM4.125 8.25V6.75H5.625V8.25H4.125ZM7.125 14.25V12.75H10.125V14.25H7.125ZM7.125 11.25V9.75H16.875V11.25H7.125ZM7.125 8.25V6.75H16.875V8.25H7.125ZM14.5875 17.25L11.4 14.0625L12.4688 12.9938L14.5875 15.1125L18.825 10.875L19.875 11.9625L14.5875 17.25Z"
                fill={color}
            />
        </g>
    </svg>
);

export default SvgCheckedList;

