import Form from './pages/form';
import {h, render} from 'preact';

const init = (formContainer) => {
    if (formContainer) {
        const renderContainer = document.createElement('div');
        const formId = formContainer.getAttribute('data-form-id');
        const companyId = formContainer.getAttribute('data-company-id');
        const vbox = formContainer.getAttribute('data-vbox');
        const shadow = formContainer.attachShadow({mode: 'open'});

        shadow.appendChild(renderContainer);
        formContainer.style.border = 'none';

        render(h(Form, {formId, companyId, vbox, isEmbedded: true}), renderContainer);
    }
};

const formContainers = document.querySelectorAll('.nimble-form');

if (formContainers) {
    formContainers.forEach((form) => {
        init(form);
    });
}


const App = () => {
    const searchParams = new URLSearchParams(location.search);
    const formId = searchParams.get('formId');
    const companyId = searchParams.get('companyId');
    const isEmbedded = !window.location.host.includes('devnimble.com')
        && !window.location.host.includes('nimble.com');

    if (isEmbedded) return null;

    return <Form
        formId={formId}
        companyId={companyId}
    />;
};

export default App;
