import React from 'react';

const SvgCreditCard = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <g clipPath="url(#credit-card_svg__a)">
            <path
                fill={color}
                d="M18 6H6c-.832 0-1.492.668-1.492 1.5l-.008 9c0 .832.668 1.5 1.5 1.5h12c.832 0 1.5-.668 1.5-1.5v-9c0-.832-.668-1.5-1.5-1.5Zm0 10.5H6V12h12v4.5ZM18 9H6V7.5h12V9Z"
            />
        </g>
        <defs>
            <clipPath id="credit-card_svg__a">
                <path fill="#fff" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default SvgCreditCard;
