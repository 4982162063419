import React from 'react';

const SvgInternational = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M12 4.5c-4.1 0-7.5 3.4-7.5 7.5s3.4 7.5 7.5 7.5 7.5-3.4 7.5-7.5-3.4-7.5-7.5-7.5ZM17.2 9H15c-.2-.9-.6-1.8-1-2.7 1.3.5 2.5 1.5 3.2 2.7ZM12 6.1c.6.9 1.1 1.9 1.4 3h-2.9c.4-1.1.9-2.1 1.5-3Zm-5.8 7.4c-.1-.5-.2-1-.2-1.5s.1-1 .2-1.5h2.5c-.1.5-.1 1-.1 1.5s0 1 .1 1.5H6.2Zm.6 1.5H9c.2.9.6 1.8 1 2.7-1.3-.5-2.5-1.4-3.2-2.7ZM9 9H6.8c.7-1.2 1.9-2.2 3.2-2.7-.4.9-.7 1.8-1 2.7Zm3 9c-.6-.9-1.1-1.9-1.4-3h2.9c-.4 1.1-.9 2.1-1.5 3Zm1.8-4.5h-3.5c-.1-.5-.1-1-.1-1.5s.1-1 .1-1.5h3.5c.1.5.1 1 .1 1.5 0 .6-.1 1.1-.1 1.5Zm.1 4.2c.5-.8.8-1.7 1-2.7h2.2c-.6 1.3-1.8 2.2-3.2 2.7Zm1.4-4.2c.1-.5.1-1 .1-1.5s0-1-.1-1.5h2.5c.1.5.2 1 .2 1.5s-.1 1-.2 1.5h-2.5Z"
        />
    </svg>
);

export default SvgInternational;
