import React from 'react';

const SvgHidden = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="m18.422 19.678-1.781-1.781a7.541 7.541 0 0 1-4.462 1.425c-1.063 0-2.05-.19-2.963-.572a7.32 7.32 0 0 1-2.382-1.584 7.32 7.32 0 0 1-1.584-2.382 7.607 7.607 0 0 1-.572-2.962 7.542 7.542 0 0 1 1.425-4.463L4.322 5.58l.806-.807 14.1 14.1-.806.806Zm-.169-3.394L7.716 5.747a7.541 7.541 0 0 1 4.462-1.425c1.05 0 2.031.194 2.944.581a7.445 7.445 0 0 1 2.381 1.594 7.445 7.445 0 0 1 1.594 2.381c.387.913.581 1.894.581 2.944a7.541 7.541 0 0 1-1.425 4.462Z"
        />
    </svg>
);

export default SvgHidden;
