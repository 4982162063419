import React from 'react';

const SvgDeal = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M12.3 11.2c-1.7-.4-2.2-.9-2.2-1.6 0-.8.8-1.4 2-1.4 1.3 0 1.8.6 1.9 1.6h1.7c-.1-1.3-.8-2.5-2.4-2.9V5.2h-2.2v1.6c-1.5.3-2.6 1.3-2.6 2.7 0 1.7 1.4 2.6 3.5 3.1 1.9.5 2.2 1.1 2.2 1.8 0 .5-.4 1.3-2 1.3-1.5 0-2.2-.7-2.2-1.6H8.3c.1 1.6 1.3 2.6 2.8 2.9v1.6h2.2V17c1.5-.3 2.6-1.1 2.6-2.7-.1-2-1.9-2.7-3.6-3.1Z"
        />
    </svg>
);

export default SvgDeal;
