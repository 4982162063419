import React from 'react';

const SvgVisible = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M12 6.4c-3.8 0-7 2.3-8.2 5.6 1.3 3.3 4.5 5.6 8.2 5.6 3.7 0 7-2.3 8.3-5.6C19 8.7 15.8 6.4 12 6.4Zm0 9.4c-2.1 0-3.8-1.7-3.8-3.8 0-2.1 1.7-3.8 3.8-3.8 2.1 0 3.8 1.7 3.8 3.8 0 2.1-1.7 3.8-3.8 3.8Zm0-6c-1.2 0-2.2 1-2.2 2.2 0 1.2 1 2.2 2.2 2.2 1.2 0 2.2-1 2.2-2.2 0-1.2-1-2.2-2.2-2.2Z"
        />
    </svg>
);

export default SvgVisible;
