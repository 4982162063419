import React from 'react';

const SvgSwap = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="m13.575 16.988 2.925-2.925-.806-.788-1.557 1.556V11.25h-1.125v3.581l-1.518-1.537-.806.806 2.887 2.888ZM9.9 12.75h1.125V9.169l1.537 1.518.788-.787-2.887-2.888-2.925 2.926.806.787L9.9 9.169v3.581ZM12 19.5a7.258 7.258 0 0 1-2.906-.59 7.61 7.61 0 0 1-2.39-1.613 7.607 7.607 0 0 1-1.613-2.39A7.256 7.256 0 0 1 4.5 12c0-1.037.197-2.012.59-2.925a7.496 7.496 0 0 1 1.613-2.381 7.688 7.688 0 0 1 2.39-1.603A7.257 7.257 0 0 1 12 4.5c1.037 0 2.012.197 2.925.59a7.574 7.574 0 0 1 2.381 1.604 7.575 7.575 0 0 1 1.603 2.381c.394.913.591 1.888.591 2.925a7.257 7.257 0 0 1-.59 2.906 7.689 7.689 0 0 1-1.604 2.39 7.497 7.497 0 0 1-2.381 1.613A7.304 7.304 0 0 1 12 19.5Z"
        />
    </svg>
);

export default SvgSwap;
