import React from 'react';

const SvgGraph = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M10.5 18h3V6h-3v12ZM6 18h3v-6H6v6Zm9-8.2V18h3V9.8h-3Z"
        />
    </svg>
);

export default SvgGraph;
