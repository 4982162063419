import React from 'react';

const SvgArrowsMinimize = ({color, ...props}) => (
    <svg 
        xmlns="http://www.w3.org/2000/svg"
        width="24" 
        height="24" 
        viewBox="0 0 24 24"
        fill="none"
        {...props}
    >
        <path d="M5.55 19.5L4.5 18.45L9.45 13.5H6V12H12V18H10.5V14.55L5.55 19.5ZM12 12V6H13.5V9.45L18.45 4.5L19.5 5.55L14.55 10.5H18V12H12Z" fill={color}/>
    </svg>

);

export default SvgArrowsMinimize;