import React from 'react';

const SvgSendLater = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M4.875 17.25v-4.5l6-1.5-6-1.5v-4.5L15.6 9.75h-.225c-1.463 0-2.703.516-3.722 1.547-1.019 1.031-1.528 2.278-1.528 3.74l-5.25 2.213Zm10.5 1.5c-1.037 0-1.922-.366-2.653-1.097-.731-.731-1.097-1.615-1.097-2.653 0-1.037.366-1.922 1.097-2.653.731-.731 1.616-1.097 2.653-1.097 1.038 0 1.922.366 2.653 1.097.731.731 1.097 1.616 1.097 2.653 0 1.038-.366 1.922-1.097 2.653-.731.731-1.615 1.097-2.653 1.097Zm1.238-1.988.524-.524-1.387-1.388v-2.1H15v2.4l1.613 1.612Z"
        />
    </svg>
);

export default SvgSendLater;
