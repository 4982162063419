import React from 'react';

const SvgDraft = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M7.5 19.5c-.412 0-.766-.147-1.06-.44A1.445 1.445 0 0 1 6 18V6c0-.412.147-.766.44-1.06.294-.293.647-.44 1.06-.44h6L18 9v9c0 .413-.147.766-.44 1.06-.294.293-.647.44-1.06.44h-9Zm5.25-9.75h3.75L12.75 6v3.75Z"
        />
    </svg>
);

export default SvgDraft;
