import React from 'react';

const SvgNotification = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M16.5 14.8V11c0-2.3-1.2-4.2-3.4-4.7v-.5c0-.6-.5-1.1-1.1-1.1-.6 0-1.1.5-1.1 1.1v.5c-2.2.5-3.4 2.4-3.4 4.7v3.8L6 16.3v.8h12v-.8l-1.5-1.5ZM12 19.3c.8 0 1.5-.7 1.5-1.5h-3c0 .8.7 1.5 1.5 1.5Z"
        />
    </svg>
);

export default SvgNotification;
