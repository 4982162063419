import React from 'react';

const SvgPaperPlane = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path fill={color} d="M5 18.8 20.7 12 5 5.3v5.2L16.2 12 5 13.5v5.3Z" />
    </svg>
);

export default SvgPaperPlane;
