import React from 'react';

const SvgFilter = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M12 5.3H5v2.5l5.5 5.3v5.6H12l1.5-1.2v-4.4L19 7.8V5.3h-7Z"
        />
    </svg>
);

export default SvgFilter;
