import React from 'react';

const SvgLocation = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M12 4.5c-2.9 0-5.2 2.3-5.2 5.2 0 3.9 5.2 9.8 5.2 9.8s5.3-5.8 5.3-9.8c0-2.9-2.4-5.2-5.3-5.2Zm0 7.1c-1 0-1.9-.8-1.9-1.9 0-1.1.8-1.9 1.9-1.9 1.1 0 1.9.8 1.9 1.9 0 1.1-.9 1.9-1.9 1.9Z"
        />
    </svg>
);

export default SvgLocation;
