import React from 'react';
import { useEffect, useRef, useState } from 'preact/hooks';
import { useResizeObserver } from 'COMMON/helpers/hooks/useResizeObserver';
import { Utils } from 'COMMON/utilities';
import { useFormValidation } from 'WEBFORMS/config/hooks/use-form-validation';
import { FORM_CSS_VARIABLES } from 'WEBFORMS/form-css-variables';
import PoweredByNimbleLabel from 'WEBFORMS/fields/components/powered-by-nimble-label/_powered-by-nimble-label';
import SingleLineField from 'WEBFORMS/fields/single-line-field';
import MultiLineField from 'WEBFORMS/fields/multi-line-field';
import DropdownField from 'WEBFORMS/fields/dropdown-field';
import SubmitButton from 'WEBFORMS/fields/submit-button';
import CheckboxField from 'WEBFORMS/fields/checkbox-field';
import EmailField from 'WEBFORMS/fields/email-field';
import NumberField from 'WEBFORMS/fields/number-field';
import WebForm, { WebFormBrandLogo, WebFormDescription } from 'WEBFORMS/form';
import {
    COMPONENTS_PROPS_KEYS,
    FIELD_LABEL_POSITIONS,
    FIELD_TYPES,
    GLOBAL_CSS_VARIABLES,
    parseSize,
} from 'WEBFORMS/config';
import LoaderPage from './loader-page';
import PostSubmissionPage from './post-submission-page';
import ErrorPage from './error-page';
import { getRequestOrigin } from '../utils';
import styles from '../styles/index.less';

/**
 * @typedef {import('web-forms/resources/js/form-builder-slice/types.js').Field} Field - form builder field 
 */

/**
 * @typedef {object} FormField
 * @property {string} field_id - ID of field
 * @property {Field} field_definition - form builder field.
 * @property {?string} archived_at - Date when string was archived.
 */

/**
 * @typedef {object} ComponentProps
 * @property {string} theme
 * @property {string} fieldSize
 * @property {string} buttonForm
 * @property {string} buttonType
 * @property {string} buttonLabel
 * @property {string} presetValue
 * @property {string} fieldLabelPos
 * @property {string} logoAlignment
 * @property {boolean} fieldWithIcons
 * @property {string} fieldBorderType
 * @property {string} logoColorVariable
 */

/**
 * @typedef {object} Styling
 * @property {object} globalStyles - Global styles for the form.
 * @property {ComponentProps} componentProps - Component properties for the form.
 */

/**
 * @typedef {object} PostSubmissionAction
 * @property {string} action_type - Type of post submission action.
 * @property {string} title - Title of the post submission message.
 * @property {string} message - Content of the post submission message.
 */

/**
 * @typedef {object} FormData
 * @property {string} form_name - Name of the form.
 * @property {string} description - Description of the form.
 * @property {?string} logo - Logo URL of the form.
 * @property {string} icon_id - Icon ID of the form.
 * @property {FormField[]} fields - Array of form fields.
 * @property {Styling} styling - Styling information for the form.
 * @property {PostSubmissionAction} post_submission_action - Post submission action details.
 * @property {string[]} status_options - Array of status options.
 * @property {boolean} use_capture - Indicates if capture is used.
 * @property {string} form_type - Type of the form.
 */

/**
 * Form component for rendering and managing a web form.
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.formId - The ID of the form.
 * @param {string} props.companyId - The ID of the company.
 * @param {string} props.vbox - The vbox name.
 * @param {boolean} [props.isEmbedded=false] - Flag to indicate if the form is embedded.
 * @returns {JSX.Element} The rendered form.
 */
const Form = ({formId, companyId, vbox, isEmbedded = false}) => {
    const formRef = useRef(null);
    /** @type {[FormData | null, React.Dispatch<React.SetStateAction<FormData | null>>]} */
    const [formData, setFormData] = useState(/** @type {FormData | null} */ (null));
    const [fieldsLabelPosition, setFieldsLabelPosition] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isPostSubmissionViewShown, setIsPostSubmissionViewShown] = useState(false);
    const [error, setError] = useState(null);
    const {
        isSubmitDisabled,
        validate,
        isValidForm
    } = useFormValidation({
        formElement: formRef.current,
    });

    useResizeObserver(formRef, ({width, element}) => {
        const minWidth = formData.styling.globalStyles[GLOBAL_CSS_VARIABLES.formMinWidth];
        const labelPosition = formData.styling.componentProps[COMPONENTS_PROPS_KEYS.fieldLabelPos];

        if (width <= parseSize(minWidth)) {
            element.style.paddingLeft = '16px';
            element.style.paddingRight = '16px';
            element.style.minWidth = 'unset';
            setFieldsLabelPosition(FIELD_LABEL_POSITIONS.above);
        } else {
            element.style.paddingLeft = '54px';
            element.style.paddingRight = '54px';
            element.style.minWidth = `var(${GLOBAL_CSS_VARIABLES.formMinWidth})`;
            setFieldsLabelPosition(labelPosition);
        }
    }, [formData]);

    /**
     * Checks if the status code is a successful status.
     * @param {number} status - The HTTP status code.
     * @returns {boolean} True if the status is successful, otherwise false.
     */
    const getSuccessStatus = (status) => (status >= 200 && status < 300) || (status === 304);

    useEffect(() => {
        if (formRef.current && !isEmbedded) {
            const element = Array.from(formRef.current.elements).find((el) => {
                return ['input', 'textarea'].includes(el.nodeName.toLowerCase());
            });
            element && setTimeout(() => element.focus(), 300);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formRef.current, isEmbedded]);

    useEffect(() => {
        if (formId && companyId) {
            fetch(`${getRequestOrigin({vbox, isEmbedded})}/api/v1/webforms/${companyId}/${formId}`)
                .then((resp) => resp.json().then((data) => ({data, status: resp.status})))
                .then(({data, status}) => {
                    if (getSuccessStatus(status)) {
                        setFormData(data);
                        setFieldsLabelPosition(data.styling.componentProps[COMPONENTS_PROPS_KEYS.fieldLabelPos]);
                        const {globalStyles} = data.styling;
                        const rootClasNameSelector = `.nimble-form[data-form-id="${formId}"]`;
                        Object.keys(globalStyles).forEach((cssKey) => {
                            FORM_CSS_VARIABLES.setValue(cssKey, globalStyles[cssKey], rootClasNameSelector);
                        });
                        const fontImportUrl = globalStyles?.[GLOBAL_CSS_VARIABLES.fontImportUrl];
                        addFontToHead(fontImportUrl);
                    } else {
                        setError({message: data.human_readable_message});
                    }
                }).catch((error) => {
                    setError({message: error.message});
                }).finally(() => {
                    setIsLoading(false);
                });
        } else {
            !companyId && setError({message: 'CompanyId is missing in URL'});
            !formId && setError({message: 'FormId is missing in URL'});
        }

    }, [formId, companyId, vbox, isEmbedded]);

    /**
     * Extracts values from hidden fields.
     * @param {FormField[]} fields - The fields data.
     * @returns {Object} Object containing hidden field values.
     */
    const getValuesFromHiddenFields = (fields) => {
        const fieldsData = fields.map((field) => field.field_definition);
        const hiddenFields = Utils.array.getItemsByPropValue({
            array: fieldsData,
            prop: 'field_type',
            value: FIELD_TYPES.hidden,
        });
        const searchParams = new URLSearchParams(window.location.search);
        return hiddenFields.reduce((acc, field) => {
            const value = searchParams.get(field.field_name);
            acc[field.field_name] = {value: value?.trim() || null};
            return acc;
        }, {});
    };

    /**
     * Adds a font import URL to the document head.
     * @param {string} fontImportUrl - The URL to import the font from.
     */
    const addFontToHead = (fontImportUrl) => {
        const fontImportBlockId = 'nimble-font-faces';
        if (!fontImportUrl || document.getElementById(fontImportBlockId)) {
            return;
        }
        const style = document.createElement('style');
        style.setAttribute('id', fontImportBlockId);

        const importFontCss = fontImportUrl && `@import url('${fontImportUrl}');`;

        style.appendChild(document.createTextNode(importFontCss));
        document.head.appendChild(style);
    };

    /**
     * Returns appropriate form field based on field type.
     * @param {Object} props - Properties containing field data.
     * @param {FormField} props.field - Field data.
     * @param {string} props.fieldsLabelPosition - Label position for fields.
     * @returns {JSX.Element|undefined} The rendered form item.
     */
    const getFormItem = ({field, fieldsLabelPosition}) => {
        const {field_definition, field_id, archived_at} = field;
        if (archived_at) return;
        const {label, field_type, required, field_name, placeholder, icon_id, options, help_text, default_value} = field_definition;
        const icon = formData?.styling?.componentProps[COMPONENTS_PROPS_KEYS.fieldWithIcons] ? icon_id : null;

        const commonProps = {
            name: field_name,
            placeholder: placeholder,
            id: field_id,
            icon: icon,
            isRequired: required,
            label: label,
            predefinedValue: default_value,
            helpText: help_text,
            labelPos: fieldsLabelPosition,
            borderType: formData?.styling?.componentProps[COMPONENTS_PROPS_KEYS.fieldBorderType],
        };

        switch (field_type) {
            case FIELD_TYPES.singleline:
                return <SingleLineField {...commonProps} />;
            case FIELD_TYPES.multiline:
                return <MultiLineField {...commonProps} />;
            case FIELD_TYPES.number:
                return <NumberField {...commonProps} />;
            case FIELD_TYPES.email: 
                return <EmailField {...commonProps} />;   
            case FIELD_TYPES.dropdown:
                return <DropdownField {...commonProps} options={options} />;
            case FIELD_TYPES.checkbox:
                return (
                    <CheckboxField
                        accentColor={formData?.styling?.globalStyles[GLOBAL_CSS_VARIABLES.accentColor]}
                        name={field_name}
                        id={field_id}
                        label={label}
                        isRequired={required}
                        predefinedValue={default_value}
                    />
                );
        }
    };

    /**
     * Returns a function that checks if a form element is a form field.
     * @param {FormField[]} formFields - The form fields data.
     * @returns {Function} Function that checks if a form element is a form field.
     */
    const isFormField = (formFields) => (formElement) => {
        const availableFieldsNames = formFields.map((formField) => formField?.field_definition?.field_name);
        return availableFieldsNames.includes(formElement.name);
    };

    /**
     * Handles form submission.
     * @param {Event} e - The form submission event.
     */
    const onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (isLoading || isSubmitDisabled || !isValidForm()) return;

        const formValues = Array.from(e.target)
            .filter(isFormField(formData.fields))
            .reduce((acc, formElement) => {
                const isCheckbox = formElement.type === 'checkbox';
                const value = isCheckbox ? formElement.checked : (formElement.value.trim() || null);
                acc[formElement.name] = {value};
                return acc;
            }, {});

        const hiddenValues = getValuesFromHiddenFields(formData.fields);

        setIsLoading(true);
        fetch(`${getRequestOrigin({vbox, isEmbedded})}/api/v1/webforms/${companyId}/${formId}/responses`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                response_values: {
                    ...formValues,
                    ...hiddenValues
                }
            }),
        }).then((response) => response.json().then((data) => ({data, status: response.status})))
            .then(({data, status}) => {
                if (status && !getSuccessStatus(status)) {
                    setError({message: data.human_readable_message});
                } else {
                    setIsPostSubmissionViewShown(true);
                }
            }).catch((error) => {
                setError({message: error.message});
            }).finally(() => {
                setIsLoading(false);
            });
    };

    if (error) {
        return <ErrorPage title="Oops! Something went wrong!" message={error?.message} withFormStyles={!!formData} />;
    }

    if (isLoading && !formData) {
        return <LoaderPage />;
    }

    if (isPostSubmissionViewShown) {
        return <PostSubmissionPage action={formData?.post_submission_action} />;
    }

    return (
        <>
            <style>
                {styles.toString()}
            </style>
            <WebForm onSubmit={onSubmit} onInput={validate} onKeyDown={validate} ref={formRef}>
                <div className="form-top-descriptive-section">
                    <WebFormBrandLogo src={formData?.logo?.logo_url}/>
                    {!!formData?.description && <WebFormDescription description={formData?.description}/>}
                </div>
                {formData.fields.map((field) => getFormItem({field, fieldsLabelPosition}))}
                <SubmitButton
                    disabled={isSubmitDisabled}
                    label={formData?.styling?.componentProps[COMPONENTS_PROPS_KEYS.buttonLabel]}
                    type={formData?.styling?.componentProps[COMPONENTS_PROPS_KEYS.buttonType]}
                    form={formData?.styling?.componentProps[COMPONENTS_PROPS_KEYS.buttonForm]}
                    accentColor={formData?.styling?.globalStyles[GLOBAL_CSS_VARIABLES.accentColor]}
                    isLoading={isLoading}
                />
                <PoweredByNimbleLabel
                    color={formData?.styling?.globalStyles[formData?.styling?.componentProps[COMPONENTS_PROPS_KEYS.logoColorVariable]]}
                    alignment={formData?.styling?.componentProps[COMPONENTS_PROPS_KEYS.logoAlignment]}
                    isClickable
                />
            </WebForm>
        </>
    );
};

export default Form;
