import React from 'react';
const SvgMarkAsUnread = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M6.5 16.25q-.619 0-1.06-.44A1.45 1.45 0 0 1 5 14.75V8.863q0-.32.16-.572.158-.255.44-.404L11.375 5l5.663 2.888q.261.13.403.365.14.234.197.497h-2.194l-4.069-2.062L6.5 9.106zm2.25 3q-.619 0-1.06-.44a1.45 1.45 0 0 1-.44-1.06V11q0-.619.44-1.06.442-.44 1.06-.44h9.75q.62 0 1.06.44.44.441.44 1.06v6.75q0 .62-.44 1.06t-1.06.44zm4.875-4.238L18.5 12.5V11l-4.875 2.512L8.75 11v1.5z"
        />
    </svg>
);
export default SvgMarkAsUnread;
