import React from 'react';

const SvgUnLink = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path fill={color} d="M17.6629 14.8127L16.5379 13.6502C17.0379 13.5127 17.4441 13.2471 17.7566 12.8533C18.0691 12.4596 18.2254 12.0002 18.2254 11.4752C18.2254 10.8502 18.0066 10.3189 17.5691 9.88145C17.1316 9.44395 16.6004 9.2252 15.9754 9.2252H12.9754V7.7252H15.9754C17.0129 7.7252 17.8973 8.09082 18.6285 8.82207C19.3598 9.55332 19.7254 10.4377 19.7254 11.4752C19.7254 12.1877 19.541 12.8439 19.1723 13.4439C18.8035 14.0439 18.3004 14.5002 17.6629 14.8127ZM15.1129 12.2252L13.6129 10.7252H15.2254V12.2252H15.1129ZM18.0754 19.4252L4.27539 5.6252L5.32539 4.5752L19.1254 18.3752L18.0754 19.4252ZM11.4754 15.2252H8.47539C7.43789 15.2252 6.55352 14.8596 5.82227 14.1283C5.09102 13.3971 4.72539 12.5127 4.72539 11.4752C4.72539 10.6127 4.98789 9.84395 5.51289 9.16895C6.03789 8.49395 6.71289 8.0502 7.53789 7.8377L8.92539 9.2252H8.47539C7.85039 9.2252 7.31914 9.44395 6.88164 9.88145C6.44414 10.3189 6.22539 10.8502 6.22539 11.4752C6.22539 12.1002 6.44414 12.6314 6.88164 13.0689C7.31914 13.5064 7.85039 13.7252 8.47539 13.7252H11.4754V15.2252ZM9.22539 12.2252V10.7252H10.4441L11.9254 12.2252H9.22539Z" />

    </svg>

);

export default SvgUnLink;
