import React from 'react';

const SvgPhone = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M18 14.5c-.9 0-1.8-.1-2.6-.4-.3-.1-.6 0-.8.2l-1.2 1.5c-2.1-1-4.1-2.9-5.2-5.1l1.5-1.2c.2-.2.3-.5.2-.8-.3-.8-.4-1.7-.4-2.6 0-.4-.3-.7-.7-.7H6.2c-.4 0-.9.2-.9.7 0 7 5.8 12.8 12.8 12.8.5 0 .7-.5.7-.9v-2.6c0-.5-.4-.9-.8-.9Z"
        />
    </svg>
);

export default SvgPhone;
