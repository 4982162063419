import React, {useState, useEffect} from 'react';
import Icon from 'MAINAPPJS/components/icons/icon/icon';
import {
    BORDER_TYPE_CLASS_NAMES,
    LABEL_POSITION_CLASS_NAMES,
    FIELD_LABEL_POSITIONS,
} from 'WEBFORMS/config';

const SingleLineField = ({
    name,
    id,
    label,
    isRequired,
    icon,
    instantlyShowErrorOnInvalidation = false,
    predefinedValue,
    labelPos,
    helpText,
    borderType,
    placeholder,
    disabled = false,
    setLabelRef,
}) => {
    const [value, setValue] = useState(predefinedValue || '');
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        setValue(predefinedValue || '');
    }, [predefinedValue]);

    const onFocus = () => setIsFocused(true);

    const onBlur = () => setIsFocused(false);

    const onChange = (e) => setValue(e.target.value);

    const classNames = ['singleline-field', 'field-box', LABEL_POSITION_CLASS_NAMES[labelPos]];
    isFocused && classNames.push('__focused');
    !!value.trim() && classNames.push('__filled');
    !!icon && classNames.push('__with-icon');
    instantlyShowErrorOnInvalidation && classNames.push('was-validated');
    placeholder && classNames.push('__with-placeholder');
    disabled && classNames.push('__disabled');

    const labelClassNames = ['singleline-field-label'];
    if (placeholder && labelPos === FIELD_LABEL_POSITIONS.inside) {
        labelClassNames.push('__active');
    }

    const errorMsg = 'This field is required';

    const iconJSX = !!icon && <Icon iconValue={icon} className="input-icon" />;

    const isEmpty = !value.trim().length;
    const isInvalid = isRequired && isEmpty;
    const tabIndex = disabled ? -1 : 1;

    return (
        <div className={classNames.join(' ')}>
            <label htmlFor={id} className={labelClassNames.join(' ')} ref={setLabelRef} data-required={isRequired} title={label}>
                {labelPos === FIELD_LABEL_POSITIONS.left && iconJSX}
                <span>{label}</span>
            </label>
            <div className="singleline-field-input-wrapper">
                {labelPos !== FIELD_LABEL_POSITIONS.left && iconJSX}
                <input
                    name={name}
                    id={id}
                    className={`singleline-field-input ${BORDER_TYPE_CLASS_NAMES[borderType]}`}
                    value={value}
                    aria-invalid={isInvalid}
                    data-empty={isEmpty}
                    data-invalid={isInvalid}
                    data-required={isRequired}
                    placeholder={placeholder}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    tabIndex={tabIndex}
                    aria-errormessage={`error-msg-${id}`}
                    aria-describedby={helpText ? `help-text-${id}` : ''}
                />
                <span className="required-label-placeholder" />
                <div className="messages">
                    {isRequired && <p className="error-msg" id={`error-msg-${id}`} title={errorMsg}>{errorMsg}</p>}
                    {!!helpText && <p className="help-text" id={`help-text-${id}`} title={helpText}>{helpText}</p>}
                </div>
            </div>
        </div>
    );
};

export default SingleLineField;
