export const BORDER_TYPES = Object.freeze({
    all: 'all',
    bottom: 'bottom',
    none: 'none',
});

export const FIELD_LABEL_POSITIONS = Object.freeze({
    inside: 'inside',
    above: 'above',
    left: 'left',
});

export const LABEL_POSITION_CLASS_NAMES = {
    [FIELD_LABEL_POSITIONS.above]: '__above',
    [FIELD_LABEL_POSITIONS.inside]: '__inside',
    [FIELD_LABEL_POSITIONS.left]: '__left',
};

export const BORDER_TYPE_CLASS_NAMES = {
    [BORDER_TYPES.all]: '__all-borders',
    [BORDER_TYPES.bottom]: '__bottom-border',
    [BORDER_TYPES.none]: '__no-borders',
};

export const FIELD_SIZES = Object.freeze({
    small: 'small',
    medium: 'medium',
    large: 'large',
});

export const MAX_FIELD_BORDER_RADIUS = Object.freeze({
    [FIELD_SIZES.small]: 20,
    [FIELD_SIZES.medium]: 25,
    [FIELD_SIZES.large]: 30,
});

export const MIN_FIELD_WIDTH = Object.freeze({
    smallField: 384,
    smallFieldWithLeftLabel: 578,
    mediumField: 470,
    mediumFieldWithLeftLabel: 632,
    largeField: 470,
    largeFieldWithLeftLabel: 632,
});

export const CHECKBOX_SIZES = Object.freeze({
    [FIELD_SIZES.small]: 14,
    [FIELD_SIZES.medium]: 15,
    [FIELD_SIZES.large]: 16,
});

export const MULTILINE_ICON_TOP_OFFSET_SIZES = Object.freeze({
    [FIELD_SIZES.small]: 7,
    [FIELD_SIZES.medium]: 13,
    [FIELD_SIZES.large]: 17,
});

export const ACTIVE_LABEL_FONT_SIZES = Object.freeze({
    [FIELD_SIZES.small]: 11,
    [FIELD_SIZES.medium]: 11,
    [FIELD_SIZES.large]: 12,
});

export const FIELD_HEIGHT_SIZES = Object.freeze({
    [FIELD_SIZES.small]: 40,
    [FIELD_SIZES.medium]: 50,
    [FIELD_SIZES.large]: 60,
});

export const DROPDOWN_HEIGHT_SIZES = Object.freeze({
    [FIELD_SIZES.small]: 30,
    [FIELD_SIZES.medium]: 40,
    [FIELD_SIZES.large]: 50,
});

export const FIELD_VERTICAL_PADDING_SIZES = Object.freeze({
    [FIELD_SIZES.small]: 10,
    [FIELD_SIZES.medium]: 16,
    [FIELD_SIZES.large]: 20,
});

export const DROPDOWN_VERTICAL_PADDING_SIZES = Object.freeze({
    [FIELD_SIZES.small]: 5,
    [FIELD_SIZES.medium]: 10,
    [FIELD_SIZES.large]: 16,
});

export const TEXT_FONT_SIZE_ABOVE_BELOW_FIELD = Object.freeze({
    [FIELD_SIZES.small]: 11,
    [FIELD_SIZES.medium]: 13,
    [FIELD_SIZES.large]: 14,
});

export const TEXT_LINE_HEIGHT_ABOVE_BELOW_FIELD = Object.freeze({
    [FIELD_SIZES.small]: 15,
    [FIELD_SIZES.medium]: 20,
    [FIELD_SIZES.large]: 20,
});

export const DISTANCES_BETWEEN_FIELDS = Object.freeze({
    [FIELD_SIZES.small]: 30,
    [FIELD_SIZES.medium]: 45,
    [FIELD_SIZES.large]: 55,
});

export const FIELD_LABEL_EXTRA_MARGIN = Object.freeze({
    [FIELD_SIZES.small]: 9,
    [FIELD_SIZES.medium]: 4,
    [FIELD_SIZES.large]: 4,
});

export const getMinFormWidth = ({size, labelPos}) => {
    const {small, medium, large} = FIELD_SIZES;
    const {left} = FIELD_LABEL_POSITIONS;

    if (size === small && labelPos === left) {
        return MIN_FIELD_WIDTH.smallFieldWithLeftLabel;
    }

    if (size === small) {
        return MIN_FIELD_WIDTH.smallField;
    }

    if (size === medium && labelPos === left) {
        return MIN_FIELD_WIDTH.mediumFieldWithLeftLabel;
    }

    if (size === medium) {
        return MIN_FIELD_WIDTH.mediumField;
    }

    if (size === large && labelPos === left) {
        return MIN_FIELD_WIDTH.largeFieldWithLeftLabel;
    }

    if (size === large) {
        return MIN_FIELD_WIDTH.largeField;
    }
};
