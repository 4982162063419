import React from 'react';

const SvgCheckboxSquared = ({color, className = '', onClick, onMouseEnter, onMouseLeave}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        className={className}
        onClick={onClick}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
    >
        <path
            fill={color}
            d="m10.7 14.9 5.288-5.287-1.05-1.05L10.7 12.8l-2.137-2.138-1.05 1.05L10.7 14.9Zm-4.2 3.6c-.412 0-.766-.147-1.06-.44A1.445 1.445 0 0 1 5 17V6.5c0-.412.147-.766.44-1.06.294-.293.647-.44 1.06-.44H17c.413 0 .766.147 1.06.44.293.294.44.647.44 1.06V17c0 .413-.147.766-.44 1.06-.294.293-.647.44-1.06.44H6.5Zm0-1.5H17V6.5H6.5V17Z"
        />
    </svg>
);

export default SvgCheckboxSquared;
