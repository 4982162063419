import React from 'react';

const SvgTextAlignLeft = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M14.3 14.2h-9v1.5h9v-1.5Zm0-6h-9v1.5h9V8.2Zm-9 4.6h13.5v-1.5H5.3v1.5Zm0 6h13.5v-1.5H5.3v1.5Zm0-13.6v1.5h13.5V5.2H5.3Z"
        />
    </svg>
);

export default SvgTextAlignLeft;
