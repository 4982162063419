import numeral from 'numeral';

/**
 * Format large numbers into a more readable string.
 * Transforms input numbers to a string representation with 'k' for thousands,
 * 'm' for millions, and 'b' for billions.
 * For numbers less than 10,000, it returns the number as a string with thousands separated by comma.
 *
 * - shortenAmount(100) returns '100'
 * - shortenAmount(1234) returns '1,234'
 * - shortenAmount(10000) returns '10.0k'
 * - shortenAmount(1000000) returns '1.0m'
 * - shortenAmount(123456789) returns '123.5m'
 * - shortenAmount(5000000000) returns '5.0b'
 * @param {number} amount - The large numerical value to be formatted
 * @returns {string} - Returns the formatted number as a string
 */
const shortenAmount = (amount) => numeral(amount).format(amount >= 10000 ? '0,000.0a' : '0,000');

/**
 * @param {string} domain - domain
 * @returns {boolean} - A flag that indicates whether the domain is valid
 */
const isValidDomain = (domain) => {
    const domainRegExp = new RegExp(
        '^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|' +
        '([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|' +
        '([a-zA-Z0-9][-_.a-zA-Z0-9]{0,61}[a-zA-Z0-9]))\\.' +
        '([a-zA-Z]{2,13}|[a-zA-Z0-9-]{2,30}.[a-zA-Z]{2,3})$'
    );

    return domainRegExp.test(domain);
};

export const string = {
    shortenAmount,
    isValidDomain,
};
