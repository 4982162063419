
import React from 'react';

const SvgPencil = ({color, ...props}) => (
    <svg 
        width="24" 
        height="24" 
        viewBox="0 0 24 24" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M5.29999 15.9V18.7H8.09998L16.4 10.4L13.6 7.6L5.29999 15.9ZM18.5 8.30001C18.8 8.00001 18.8 7.50004 18.5 7.20004L16.7 5.40005C16.4 5.10005 15.9 5.10005 15.6 5.40005L14.2 6.80001L17 9.6L18.5 8.30001Z" fill={color}/>
    </svg>

);

export default SvgPencil;
