import React from 'react';

const SvgAbc = ({color, className = '', onClick, onMouseEnter, onMouseLeave}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        className={className}
        onClick={onClick}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
    >
        <path
            fill={color}
            d="M15.5 14.5a.726.726 0 0 1-.534-.216.726.726 0 0 1-.216-.534v-3c0-.213.072-.39.216-.534A.726.726 0 0 1 15.5 10h2.25c.212 0 .39.072.534.216a.726.726 0 0 1 .216.534v.75h-1.125v-.375h-1.5v2.25h1.5V13H18.5v.75c0 .213-.072.39-.216.534a.726.726 0 0 1-.534.216H15.5Zm-5.625 0V10h3c.213 0 .39.072.534.216a.726.726 0 0 1 .216.534v.75c0 .213-.072.39-.216.534a.726.726 0 0 1-.534.216c.213 0 .39.072.534.216a.726.726 0 0 1 .216.534v.75c0 .213-.072.39-.216.534a.726.726 0 0 1-.534.216h-3ZM11 11.687h1.5v-.562H11v.563Zm0 1.688h1.5v-.563H11v.563ZM5 14.5v-3.75c0-.213.072-.39.216-.534A.726.726 0 0 1 5.75 10H8c.213 0 .39.072.534.216a.726.726 0 0 1 .216.534v3.75H7.625v-1.125h-1.5V14.5H5Zm1.125-2.25h1.5v-1.125h-1.5v1.125Z"
        />
    </svg>
);

export default SvgAbc;
