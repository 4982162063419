import React from 'react';

const SvgListWithClock = ({color, width = 24, height = 24, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        {...props}
    >
        <g transform={`scale(${width / 24})`}>
            <path
                fill={color}
                fillRule="evenodd"
                d="M15.75 18.5c-1.037 0-1.922-.366-2.653-1.097C12.366 16.672 12 15.788 12 14.75c0-1.037.366-1.922 1.097-2.653.731-.731 1.616-1.097 2.653-1.097 1.038 0 1.922.366 2.653 1.097.731.731 1.097 1.616 1.097 2.653s-.366 1.922-1.097 2.653c-.731.731-1.615 1.097-2.653 1.097Zm.375-3.9v-2.1h-.75v2.4l1.613 1.612.524-.524-1.387-1.388ZM4.125 12.75v1.5h1.5v-1.5h-1.5Zm0-3v1.5h1.5v-1.5h-1.5Zm0-3v1.5h1.5v-1.5h-1.5Zm3 6v1.5h3v-1.5h-3Zm0-3v1.5h4.708c.065-.072.133-.144.203-.214a5.152 5.152 0 0 1 2.076-1.286H7.125Zm0-3v1.5h9.75v-1.5h-9.75Z"
                clipRule="evenodd"
            />
        </g>
    </svg>
);

export default SvgListWithClock;
