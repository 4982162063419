import React from 'react';

const SvgDecreaseIndent = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M5.25 18.75v-1.5h13.5v1.5H5.25Zm6-3v-1.5h7.5v1.5h-7.5Zm0-3v-1.5h7.5v1.5h-7.5Zm0-3v-1.5h7.5v1.5h-7.5Zm-6-3v-1.5h13.5v1.5H5.25Zm3 8.25-3-3 3-3v6Z"
        />
    </svg>
);

export default SvgDecreaseIndent;
