import React from 'react';

const SvgStar = ({color, width, height, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        {...props}
    >
        <path
            transform={`scale(${width / 24})`}
            data-testid="star-icon-path"
            fill={color}
            fillRule="evenodd"
            d="m12 4 2.8 5 5.7 1.1-4 4.2.7 5.7-5.3-2.4L6.6 20l.7-5.7-4-4.2L9 9l3-5Z"
            clipRule="evenodd"
        />
    </svg>
);

export default SvgStar;
