import React from 'react';

const SvgActivityOutline = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <g clipPath="url(#activity-outline_svg__a)">
            <path
                fill={color}
                fillRule="evenodd"
                d="M18 5.25H6c-.825 0-1.5.675-1.5 1.5v10.5c0 .825.675 1.5 1.5 1.5h12c.825 0 1.5-.675 1.5-1.5V6.75c0-.825-.675-1.5-1.5-1.5Zm-7.5 10.5H6.75v-1.5h3.75v1.5Zm0-3H6.75v-1.5h3.75v1.5Zm0-3H6.75v-1.5h3.75v1.5Zm3.615 4.5L12 12.12l1.057-1.057 1.058 1.064 2.377-2.377 1.066 1.065-3.443 3.435Z"
                clipRule="evenodd"
            />
        </g>
        <defs>
            <clipPath id="activity-outline_svg__a">
                <path fill="#fff" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default SvgActivityOutline;
