import React from 'react';
const SvgMute = ({color, width = 24, height = 24, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M12.834 10c0-1.083-.667-2.083-1.583-2.5v1.417l1.5 1.5c.083-.167.083-.25.083-.417m1.5 0c0 .584-.083 1.167-.333 1.667l.916.917c.417-.75.667-1.667.667-2.584a5.63 5.63 0 0 0-4.333-5.5v1.25c1.833.583 3.083 2.25 3.083 4.25M5.167 4.334l-.833.833 2.917 2.917H4.334v3.75h2.5L10.001 15v-4.166l2.666 2.666a4.6 4.6 0 0 1-1.416.75v1.25a5.16 5.16 0 0 0 2.333-1.166l1.25 1.25.833-.834-5.666-5.583zM10.001 5 8.667 6.333l1.334 1.334z"
        />
    </svg>
);
export default SvgMute;
