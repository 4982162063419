import * as R from 'ramda';

const immutableSet = ({prop, value, object}) => {
    if (prop === undefined || value === undefined) {
        throw new ReferenceError('You have to provide "prop" and "value"');
    }

    if (typeof object !== 'object') {
        throw new TypeError('"object" argument must be of "object" type');
    }

    return R.set(R.lensProp(prop), value, object);
};

export const object = {
    immutableSet,
};
