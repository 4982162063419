import React from 'react';

const SvgNimbleSingleStar = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M8 2.035c.14-.085.437-.203 1-.203s.86.118 1 .203V4.75a.5.5 0 1 0 1 0V1.925c0-.171-.032-.345-.147-.473-.221-.247-.74-.62-1.853-.62-1.112 0-1.632.373-1.853.62-.115.128-.147.302-.147.473V4.75a.5.5 0 1 0 1 0V2.035ZM6.147 7.59a.496.496 0 0 0 .65-.365.49.49 0 0 0-.328-.566L2.653 5.421a.792.792 0 0 0-.355-.043c-.367.051-1.198.303-1.59 1.514-.393 1.21.131 1.902.398 2.16a.791.791 0 0 0 .312.172l4.348 1.411-2.673 3.683a.824.824 0 0 0-.144.292c-.088.373-.18 1.33.877 2.099 1.059.768 1.94.384 2.268.186a.825.825 0 0 0 .233-.228L9 12.987l2.673 3.68c.064.089.14.17.233.227.327.199 1.21.584 2.268-.185 1.058-.768.965-1.726.877-2.099a.823.823 0 0 0-.144-.292l-2.674-3.682 4.349-1.412a.791.791 0 0 0 .312-.172c.267-.258.791-.95.399-2.16-.393-1.21-1.224-1.463-1.591-1.514a.792.792 0 0 0-.355.043c-.469.152-.938.303-1.408.453-.854.274-1.709.548-2.56.832a.496.496 0 0 0-.318.61c.08.273.372.424.642.333.83-.28 1.665-.546 2.5-.811.476-.152.951-.303 1.426-.457a.78.78 0 0 1 .27.127c.123.09.308.277.442.693.135.415.095.674.048.82a.78.78 0 0 1-.143.261l-4.382 1.422-.332.157a.75.75 0 0 0-.285 1.119l2.841 3.912a.909.909 0 0 1-.015.37c-.04.156-.145.39-.487.638-.342.248-.597.276-.757.266a.91.91 0 0 1-.357-.1L9.607 12.12a.75.75 0 0 0-1.214 0l-2.865 3.945a.911.911 0 0 1-.357.1c-.16.01-.415-.018-.757-.266-.342-.248-.448-.482-.487-.638a.91.91 0 0 1-.015-.37l2.841-3.912a.75.75 0 0 0-.285-1.119l-.332-.157-4.382-1.422a.779.779 0 0 1-.143-.26c-.048-.147-.087-.406.048-.821.134-.416.319-.602.443-.693a.78.78 0 0 1 .269-.127c.476.155.955.303 1.434.452.784.244 1.569.487 2.342.756Z"
        />
    </svg>
);

export default SvgNimbleSingleStar;
