import React from 'react';

const SvgPlane = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M18.6 15v-1.5l-6-3.8V5.6c0-.6-.5-1.1-1.1-1.1-.6 0-1.1.5-1.1 1.1v4.1l-6 3.8V15l6-1.9v4.1l-1.5 1.1v1.1l2.6-.8 2.6.8v-1.1l-1.5-1.1v-4.1l6 1.9Z"
        />
    </svg>
);

export default SvgPlane;
