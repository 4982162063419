import React from 'react';

const SvgFlag = ({color, width = 24, height = 24, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        {...props}
    >
        <g transform={`scale(${width / 24})`}>
            <path
                fill={color}
                d="m13.9 7.7-.3-1.5H6.8V19h1.5v-5.2h4.2l.3 1.5h5.3V7.8h-4.2v-.1Z"
            />
        </g>
    </svg>);

export default SvgFlag;

