import React from 'react';

const SvgHome = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M10.5 17.9v-4.5h3v4.5h3.8v-6h2.2L12 5.1l-7.5 6.8h2.2v6h3.8Z"
        />
    </svg>
);

export default SvgHome;
