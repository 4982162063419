import React from 'react';

const SvgSuitcase = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M10.5 15v-.7H5.3v3c0 .8.7 1.5 1.5 1.5h10.5c.8 0 1.5-.7 1.5-1.5v-3h-5.3v.8h-3V15ZM18 8.3h-3V6.8l-1.5-1.5h-3L9 6.8v1.5H6c-.8 0-1.5.7-1.5 1.5V12c0 .8.7 1.5 1.5 1.5h4.5V12h3v1.5H18c.8 0 1.5-.7 1.5-1.5V9.8c0-.8-.7-1.5-1.5-1.5Zm-4.5 0h-3V6.8h3v1.5Z"
        />
    </svg>
);

export default SvgSuitcase;
