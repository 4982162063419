import React from 'react';

const SvgUnderlined = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M12.1 15.8c2.5 0 4.5-2 4.5-4.5v-6h-1.9v6c0 1.4-1.2 2.6-2.6 2.6-1.4 0-2.6-1.2-2.6-2.6v-6H7.6v6c0 2.4 2 4.5 4.5 4.5Zm-5.3 1.4v1.5h10.5v-1.5H6.8Z"
        />
    </svg>
);

export default SvgUnderlined;
