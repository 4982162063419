import React from 'react';

const SvgSpeechBubble = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="m4.5 19.5 3-3H18c.8 0 1.5-.7 1.5-1.5V6c0-.8-.7-1.5-1.5-1.5H6c-.8 0-1.5.7-1.5 1.5v13.5Z"
        />
    </svg>
);

export default SvgSpeechBubble;
