import React from 'react';

const SvgDownload = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="m7.5 13.2 4.5 4.5 4.5-4.5-1.1-1.1-2.7 2.7V4.2h-1.5v10.6l-2.7-2.7-1 1.1Zm0 6.7h9v-1.5h-9v1.5Z"
        />
    </svg>
);

export default SvgDownload;
