import React from 'react';

const SvgEuro = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M14.3 16.9c-1.9 0-3.5-1.1-4.3-2.6h4.3v-1.5H9.5c0-.2-.1-.5-.1-.8 0-.3 0-.5.1-.8h4.8V9.7H10c.8-1.6 2.4-2.6 4.3-2.6 1.2 0 2.3.4 3.2 1.2L18.8 7c-1.2-1.1-2.8-1.7-4.5-1.7-2.9 0-5.4 1.9-6.4 4.5H5.3v1.5h2.3v1.6H5.3v1.5h2.6c.9 2.6 3.4 4.5 6.4 4.5 1.7 0 3.3-.7 4.5-1.7l-1.3-1.3c-.9.6-2 1-3.2 1Z"
        />
    </svg>
);

export default SvgEuro;
