
import React from 'react';

const SvgAddContact = ({color, ...props}) => (
    <svg 
        xmlns="http://www.w3.org/2000/svg"
        width="24" 
        height="24" 
        viewBox="0 0 24 24" 
        fill="none"
        {...props}
    >
        <path 
            fill={color}
            d="M14.2 12C15.9 12 17.2 10.7 17.2 9C17.2 7.3 15.9 6 14.2 6C12.5 6 11.2 7.3 11.2 9C11.2 10.7 12.6 12 14.2 12ZM7.49998 10.5V8.3H5.99998V10.5H3.79999V12H5.99998V14.2H7.49998V12H9.69998V10.5H7.49998ZM14.2 13.5C12.2 13.5 8.19998 14.5 8.19998 16.5V18H20.2V16.5C20.2 14.5 16.3 13.5 14.2 13.5Z"
        />
    </svg>
);

export default SvgAddContact;
