import React from 'react';

const SvgItalic = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M10.5 6.8V9h1.7l-2.6 6H7.5v2.2h6V15h-1.7l2.6-6h2.1V6.8h-6Z"
        />
    </svg>
);

export default SvgItalic;
