import React from 'react';

const SvgSequences = ({color, width = 24, height = 24, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={height}
        height={width}
        fill="none"
        {...props}
    >
        <g transform={`scale(${width / 24})`}>
            <path
                fill={color}
                d="M12.75 10v4.5h1.875V16H12.75v4.5H18V16h-1.875v-1.5H18V10h-5.25ZM6 4v4.5h1.875V10H6v4.5h1.875V16H6v4.5h5.25V16H9.375v-1.5h1.875V10H9.375V8.5h1.875V4H6Z"
            />
        </g>
    </svg>
);

export default SvgSequences;
