import React from 'react';

const SvgContactMinus = ({color, width = 24, height = 24, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        {...props}
    >
        <path
            transform={`scale(${width / 24})`}
            fill={color}
            d="M14.3 12C16 12 17.3 10.7 17.3 9C17.3 7.3 16 6 14.3 6C12.6 6 11.3 7.3 11.3 9C11.3 10.7 12.6 12 14.3 12ZM3.79999 10.5V12H9.79999V10.5H3.79999ZM14.3 13.5C12.3 13.5 8.29999 14.5 8.29999 16.5V18H20.3V16.5C20.3 14.5 16.3 13.5 14.3 13.5Z"
        />

    </svg>
);

export default SvgContactMinus;
