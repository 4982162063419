import React from 'react';

const SvgClose = ({color, width = 24, height = 24, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        {...props}
    >
        <g transform={`scale(${width / 24})`}>
            <path
                data-testid="close-icon-path"
                d="M17.3002 7.80017L16.2002 6.7002L12.0002 10.9002L7.80019 6.7002L6.7002 7.80017L10.9002 12.0002L6.7002 16.2002L7.80019 17.3002L12.0002 13.1002L16.2002 17.3002L17.3002 16.2002L13.1002 12.0002L17.3002 7.80017Z"
                fill={color}/>
        </g>
    </svg>
);

export default SvgClose;
