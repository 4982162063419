import React from 'react';

const SvgMessageOutline = ({color, width = 24, height = 24, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        {...props}
    >
        <g transform={`scale(${width / 24})`}>
            <g clipPath="url(#clip0_1711_179)">
                <path
                    d="M19.5 7.5C19.5 6.675 18.825 6 18 6H6C5.175 6 4.5 6.675 4.5 7.5V16.5C4.5 17.325 5.175 18 6 18H18C18.825 18 19.5 17.325 19.5 16.5V7.5ZM18 7.5L12 11.2425L6 7.5H18ZM18 16.5H6V9L12 12.75L18 9V16.5Z"
                    fill={color}/>
            </g>
            <defs>
                <clipPath id="clip0_1711_179">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </g>
    </svg>);

export default SvgMessageOutline;

