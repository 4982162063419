import React from 'react';

const SvgWrench = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <g clipPath="url(#wrench_svg__a)">
            <path
                fill={color}
                d="m4.216 17.425 6.825-6.825c-.675-1.725-.3-3.75 1.125-5.175 1.5-1.5 3.75-1.8 5.55-.975l-3.225 3.225 2.25 2.25 3.3-3.225c.9 1.8.525 4.05-.975 5.55-1.425 1.425-3.45 1.8-5.175 1.125L7.066 20.2c-.3.3-.75.3-1.05 0l-1.725-1.725c-.375-.3-.375-.825-.075-1.05Z"
            />
        </g>
        <defs>
            <clipPath id="wrench_svg__a">
                <path fill="#fff" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default SvgWrench;
