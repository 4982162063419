import React from 'react';

const SvgMerge = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path d="M18.165 8.715H15.285V5.83505C15.285 5.11505 14.655 4.48505 13.935 4.48505H5.83499C5.11499 4.48505 4.48499 5.11505 4.48499 5.83505V13.935C4.48499 14.655 5.11499 15.285 5.83499 15.285H8.71499V18.165C8.71499 18.885 9.34499 19.515 10.065 19.515H18.165C18.885 19.515 19.515 18.885 19.515 18.165V10.065C19.515 9.345 18.885 8.715 18.165 8.715ZM5.74499 13.935V5.83505H13.845V8.715H9.97499C9.88499 8.715 9.79499 8.715 9.70499 8.715C9.61499 8.715 9.61499 8.71504 9.52499 8.80504C9.52499 8.80504 9.52499 8.80504 9.43499 8.80504C8.98499 8.98504 8.62499 9.525 8.62499 10.065V13.935H5.74499ZM10.065 18.165V15.285H13.935C14.655 15.285 15.285 14.655 15.285 13.935V10.065H18.165V18.165H10.065Z" fill={color}/>
    </svg>
);

export default SvgMerge;