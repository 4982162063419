import React from 'react';

const SvgCheckmark = ({width = 24, height = 24, color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        {...props}
    >
        <g transform={`scale(${width / 24})`}>
            <path
                data-testid="checkmark-icon-path"
                d="M10.3 14.9L6.80002 11.4L5.70001 12.5L10.2 17L18.1 9L17 8L10.3 14.9Z"
                fill={color}
            />
        </g>
    </svg>
);

export default SvgCheckmark;