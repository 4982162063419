import {CSS_VARIABLES} from 'COMMON/helpers/css-variables';

const getRoot = () => document.querySelector('.nimble-form')?.shadowRoot?.host;
const getValue = (key) => CSS_VARIABLES.getValue(key, getRoot());

const setValue = (key, value, rootClasNameSelector) => {
    const rootDiv = rootClasNameSelector ? document.querySelector(rootClasNameSelector)?.shadowRoot?.host : null;
    return CSS_VARIABLES.setValue(key, value, rootDiv ?? getRoot());
};

export const FORM_CSS_VARIABLES = {getValue, setValue};
