import React, {useEffect, useState} from 'react';
import Icon from 'MAINAPPJS/components/icons/icon/icon';
import {
    BORDER_TYPE_CLASS_NAMES,
    LABEL_POSITION_CLASS_NAMES
} from 'WEBFORMS/config';

const DropdownField = ({
    name,
    id,
    label,
    isRequired,
    icon,
    options = [],
    instantlyShowErrorOnInvalidation = false,
    predefinedValue,
    labelPos,
    helpText,
    borderType,
    placeholder,
    disabled = false,
    setLabelRef,
}) => {
    const [value, setValue] = useState(predefinedValue || '');
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        setValue(predefinedValue || '');
    }, [predefinedValue]);

    const onFocus = () => setIsFocused(true);

    const onBlur = () => setIsFocused(false);

    const onInput = (e) => setValue(e.target.value);

    const classNames = ['dropdown-field', 'field-box', LABEL_POSITION_CLASS_NAMES[labelPos]];
    isFocused && classNames.push('__focused');
    classNames.push('__filled');
    !!icon && classNames.push('__with-icon');
    instantlyShowErrorOnInvalidation && classNames.push('was-validated');
    disabled && classNames.push('__disabled');

    const errorMsg = 'This field is required';

    const iconJSX = !!icon && <Icon iconValue={icon} className="input-icon" />;

    const isEmpty = !value.trim().length;
    const isInvalid = isRequired && isEmpty;
    const tabIndex = disabled ? -1 : 1;

    return (
        <div className={classNames.join(' ')}>
            <label htmlFor={id} className="dropdown-field-label" ref={setLabelRef} data-required={isRequired} title={label}>
                {labelPos === 'left' && iconJSX}
                <span>{label}</span>
            </label>
            <div className="dropdown-field-input-wrapper">
                {labelPos !== 'left' && iconJSX}
                <div className="dropdown-field__ico-down">▾</div>
                <select
                    name={name}
                    id={id}
                    className={`dropdown-field-input ${BORDER_TYPE_CLASS_NAMES[borderType]}`}
                    value={value}
                    aria-invalid={isInvalid}
                    data-invalid={isInvalid}
                    data-required={isRequired}
                    data-empty={isEmpty}
                    placeholder={placeholder}
                    onInput={onInput}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    tabIndex={tabIndex}
                    aria-errormessage={`error-msg-${id}`}
                    aria-describedby={helpText ? `help-text-${id}` : ''}
                >
                    <option value="">{placeholder || 'Select value'}</option>
                    {options.map((option) => <option key={option} value={option}>{option}</option>)}
                </select>
                <span className="required-label-placeholder" />
                <div className="messages">
                    <p className="error-msg" id={`error-msg-${id}`} title={errorMsg}>{errorMsg}</p>
                    {!!helpText && <p className="help-text" id={`help-text-${id}`}>{helpText}</p>}
                </div>
            </div>
        </div>
    );
};

export default DropdownField;
