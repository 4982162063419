import React from 'react';

const SvgEducation = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M6.8 12.9v3l5.2 2.9 5.3-2.9v-3L12 15.8l-5.2-2.9ZM12 5.3 3.8 9.8l8.2 4.5 6.8-3.7v5.2h1.5v-6L12 5.3Z"
        />
    </svg>
);

export default SvgEducation;
