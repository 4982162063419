import React from 'react';

const SvgAvatarEmpty = () => (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_3817_24507)">
            <path d="M60 20.8333C60 15.6667 56.8333 10.8333 52 9C47.8333 7.5 42.1667 6 35 6C27.8333 6 22.1667 7.5 18 9C13.1667 10.8333 10 15.6667 10 20.8333V31V41.1667C10 46.3333 13.1667 51.1667 18 53C22.1667 54.5 27.8333 56 35 56C42.1667 56 47.8333 54.5 52 53C56.8333 51.1667 60 46.5 60 41.1667V31V20.8333Z" fill="white"/>
        </g>
        <defs>
            <filter id="filter0_d_3817_24507" x="0" y="0" width="70" height="70" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="4"/>
                <feGaussianBlur stdDeviation="5"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.216146 0 0 0 0 0.373397 0 0 0 0 0.625 0 0 0 0.5 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3817_24507"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3817_24507" result="shape"/>
            </filter>
        </defs>
    </svg>
);

export default SvgAvatarEmpty;
