import React from 'react';

const SvgCondition = ({color, width = 24, height = 24, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        {...props}
    >
        <g transform={`scale(${width / 24})`}>
            <path
                d="M13.8 18.0281L12.75 16.9781L14.7 15.0281L12.75 13.0781L13.8 12.0281L15.75 13.9781L17.7 12.0281L18.75 13.0781L16.8 15.0281L18.75 16.9781L17.7 18.0281L15.75 16.0781L13.8 18.0281ZM15.2812 11.2781L12.6187 8.61563L13.6687 7.56563L15.2625 9.15938L18.45 5.97188L19.5 7.04063L15.2812 11.2781ZM4.5 15.7781V14.2781H11.25V15.7781H4.5ZM4.5 9.77813V8.27813H11.25V9.77813H4.5Z"
                fill={color}/>
        </g>
    </svg>);

export default SvgCondition;
