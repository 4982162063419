import React from 'react';

const SvgMobile = ({color, width = 24, height = 24, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            transform={`scale(${width / 24})`}
            d="M14.5 3.8h-6c-1 0-1.9.8-1.9 1.9v12.8c0 1 .8 1.9 1.9 1.9h6c1 0 1.9-.8 1.9-1.9V5.7c0-1.1-.9-1.9-1.9-1.9Zm-3 15.7c-.6 0-1.1-.5-1.1-1.1 0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1Zm3.4-3H8.1V6h6.8v10.5Z"
        />
    </svg>
);

export default SvgMobile;
