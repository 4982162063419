import React from 'react';

const SvgLockWithPlus = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path fillRule="evenodd" clipRule="evenodd" d="M15.7 8.90002H16.5C17.3 8.90002 18 9.60002 17.8 10.4V12.8011C17.1967 12.543 16.5323 12.4 15.8345 12.4C13.0731 12.4 10.8345 14.6386 10.8345 17.4C10.8345 18.1111 10.983 18.7876 11.2506 19.4H7.3C6.5 19.4 5.8 18.7 5.8 17.9V10.4C5.8 9.60002 6.5 8.90002 7.3 8.90002H8.1V7.40002C8.1 5.30002 9.80001 3.59998 11.9 3.59998C14 3.59998 15.7 5.30002 15.7 7.40002V8.90002ZM9.6 8.90002H14.2V7.40002C14.2 6.10002 13.2 5.09998 11.9 5.09998C10.6 5.09998 9.6 6.10002 9.6 7.40002V8.90002Z" fill={color}/>
        <path d="M18.8345 16.65H16.5845V14.4H15.0845V16.65H12.8345V18.15H15.0845V20.4H16.5845V18.15H18.8345V16.65Z" fill={color}/>
    </svg>
);

export default SvgLockWithPlus;