import React from 'react';

const SvgUnmute = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M5.3 9.8v4.5h3L12 18V6L8.2 9.8H5.3ZM15.4 12c0-1.3-.8-2.5-1.9-3v6c1.1-.5 1.9-1.7 1.9-3Zm-1.9-6.6v1.5c2.2.6 3.7 2.7 3.7 5s-1.6 4.4-3.7 5v1.5c3-.7 5.2-3.4 5.2-6.6 0-3.2-2.2-5.7-5.2-6.4Z"
        />
    </svg>
);

export default SvgUnmute;
