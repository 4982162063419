import React from 'react';

const SvgRedo = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M15.75 17.25v-1.5h-5.325c-.788 0-1.472-.25-2.053-.75S7.5 13.875 7.5 13.125s.29-1.375.872-1.875c.581-.5 1.265-.75 2.053-.75h4.725l-1.95 1.95 1.05 1.05L18 9.75 14.25 6 13.2 7.05 15.15 9h-4.725c-1.213 0-2.253.394-3.122 1.181C6.434 10.97 6 11.95 6 13.125s.434 2.156 1.303 2.944c.869.787 1.91 1.181 3.122 1.181h5.325Z"
        />
    </svg>
);

export default SvgRedo;
