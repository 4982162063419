import React from 'react';

const SvgDocument = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            d="M17.2999 5H14.1999C13.8999 4.1 13.0999 3.5 12.0999 3.5C11.0999 3.5 10.2999 4.1 9.9999 5H6.8999C6.0999 5 5.3999 5.7 5.3999 6.5V17C5.3999 17.8 6.0999 18.5 6.8999 18.5H17.3999C18.1999 18.5 18.8999 17.8 18.8999 17V6.5C18.7999 5.7 18.0999 5 17.2999 5ZM11.9999 5C12.3999 5 12.7999 5.29999 12.7999 5.79999C12.7999 6.19999 12.4999 6.59998 11.9999 6.59998C11.4999 6.59998 11.1999 6.29999 11.1999 5.79999C11.2999 5.39999 11.5999 5 11.9999 5ZM13.4999 15.5H8.2999V14H13.4999V15.5ZM15.7999 12.5H8.2999V11H15.7999V12.5ZM15.7999 9.5H8.2999V8H15.7999V9.5Z"
            fill={color}/>
    </svg>
);

export default SvgDocument;