import {useCallback, useState} from 'react';
import _ from 'underscore';

export const useFormValidation = ({formElement}) => {
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

    const validateForm = useCallback((emptyOptionalCallback) => {
        if (!formElement) return false;

        formElement.classList.add('was-validated');

        const fields = Array.from(formElement.elements).filter((el) => ['input', 'textarea', 'select'].includes(el.nodeName.toLowerCase()));
        const requiredFields = fields.filter((el) => el.dataset.required === 'true');
        const invalidRequiredFields = requiredFields.filter((el) => el.dataset.invalid === 'true');

        const onlyCheckboxes = fields.every((el) => el.type === 'checkbox');
        const optionalFields = fields.filter((el) => el.dataset.required === 'false');
        const emptyOptionalFields = optionalFields.filter((el) => el.dataset.empty === 'true');

        if (requiredFields.length === 0 && emptyOptionalFields.length === optionalFields.length && !onlyCheckboxes) {
            typeof emptyOptionalCallback === 'function' && emptyOptionalCallback(formElement);
        }
 
        const isInvalid = requiredFields.length
            ? Boolean(invalidRequiredFields.length)
            : ((onlyCheckboxes && !emptyOptionalFields) || (emptyOptionalFields.length === optionalFields.length));
            
        return !isInvalid;
    }, [formElement]);

    const setDebouncedSubmitDisalbed = _.debounce(() => {
        setIsSubmitDisabled(!validateForm());
    }, 100);

    const validate = useCallback(setDebouncedSubmitDisalbed, [formElement, setDebouncedSubmitDisalbed]);

    const isValidForm = useCallback(() => {
        const isValid = validateForm((formElement) => {
            formElement.classList.add('__invalid-form');
            setTimeout(() => {
                formElement.classList.remove('__invalid-form');
            }, 2000);
        });
        setIsSubmitDisabled(!isValid);
        return isValid;
    },[validateForm]);

    return {
        isSubmitDisabled,
        isValidForm,
        validate
    };
};
