import React from 'react';

const SvgEmail = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <g clipPath="url(#email_svg__a)">
            <path
                fill={color}
                d="M12.5 5C8.36 5 5 8.36 5 12.5c0 4.14 3.36 7.5 7.5 7.5h3.75v-1.5H12.5c-3.255 0-6-2.745-6-6s2.745-6 6-6 6 2.745 6 6v1.072c0 .593-.532 1.178-1.125 1.178s-1.125-.585-1.125-1.178V12.5c0-2.07-1.68-3.75-3.75-3.75-2.07 0-3.75 1.68-3.75 3.75a3.751 3.751 0 0 0 6.405 2.648c.488.667 1.328 1.102 2.22 1.102 1.477 0 2.625-1.2 2.625-2.678V12.5C20 8.36 16.64 5 12.5 5Zm0 9.75a2.247 2.247 0 0 1-2.25-2.25 2.247 2.247 0 0 1 2.25-2.25 2.247 2.247 0 0 1 2.25 2.25 2.247 2.247 0 0 1-2.25 2.25Z"
            />
        </g>
        <defs>
            <clipPath id="email_svg__a">
                <path fill="#fff" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default SvgEmail;
