import React from 'react';

const SvgComment = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path d="M4.5 19.5L7.5 16.5H18C18.8 16.5 19.5 15.8 19.5 15V6C19.5 5.2 18.8 4.5 18 4.5H6C5.2 4.5 4.5 5.2 4.5 6V19.5Z" fill={color}/>
    </svg>
);

export default SvgComment;