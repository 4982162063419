import React from 'react';

const SvgRefresh = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path d="M16.2 7.79999C15.1 6.69999 13.6 6 12 6C8.7 6 6 8.7 6 12C6 15.3 8.7 18 12 18C14.8 18 17.1 16.1 17.8 13.5H16.2C15.6 15.2 13.9 16.5 12 16.5C9.5 16.5 7.5 14.5 7.5 12C7.5 9.5 9.5 7.5 12 7.5C13.2 7.5 14.4 7.99999 15.2 8.79999L12.8 11.2H18V6L16.2 7.79999Z" fill={color}/>
    </svg>
);

export default SvgRefresh;