import React from 'react';

const SvgBigHouse = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M12 8.3v-3H4.5v13.5h15V8.3H12Zm-4.5 9H6v-1.5h1.5v1.5Zm0-3H6v-1.5h1.5v1.5Zm0-3H6V9.8h1.5v1.5Zm0-3H6V6.8h1.5v1.5Zm3 9H9v-1.5h1.5v1.5Zm0-3H9v-1.5h1.5v1.5Zm0-3H9V9.8h1.5v1.5Zm0-3H9V6.8h1.5v1.5Zm7.5 9h-6v-1.5h1.5v-1.5H12v-1.5h1.5v-1.5H12V9.8h6v7.5Zm-1.5-6H15v1.5h1.5v-1.5Zm0 3H15v1.5h1.5v-1.5Z"
        />
    </svg>
);

export default SvgBigHouse;
