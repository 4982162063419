import React from 'react';

const SvgFastFood = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M16.5 20.3h1.2c.6 0 1.1-.5 1.2-1.1l1.2-12.4h-3.8v-3H15v3h-3.7l.2 1.8c1.3.4 2.5 1 3.2 1.7 1.1 1.1 1.8 2.2 1.8 4v6Zm-12.7-.8v-.7H15v.7c0 .4-.3.8-.8.8H4.4c-.3 0-.6-.3-.6-.8ZM15 14.3c0-6-11.3-6-11.3 0H15ZM3.8 15.8H15v1.5H3.8v-1.5Z"
        />
    </svg>
);

export default SvgFastFood;
