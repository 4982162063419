import React from 'react';

const SvgImage = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M17.3 5.3H6.8c-.8 0-1.5.7-1.5 1.5v10.5c0 .8.7 1.5 1.5 1.5h10.5c.8 0 1.5-.7 1.5-1.5V6.8c0-.8-.7-1.5-1.5-1.5Zm0 12H6.8V6.8h10.5v10.5Zm-3.8-5L11.4 15l-1.5-1.8-2.1 2.6H16l-2.5-3.5Z"
        />
    </svg>
);

export default SvgImage;
