import React from 'react';

const SvgTextAlignCenter = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M8.3 14.3v1.5h7.5v-1.5H8.3Zm-3 4.5h13.5v-1.5H5.3v1.5Zm0-6h13.5v-1.5H5.3v1.5Zm3-4.5v1.5h7.5V8.3H8.3Zm-3-3v1.5h13.5V5.3H5.3Z"
        />
    </svg>
);

export default SvgTextAlignCenter;
