import React from 'react';

const SvgLocked = ({color, width = 24, height = 24, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            transform={`scale(${width / 24})`}
            data-testid="locked-icon-path"
            d="M16.5 8.9h-.8V7.4c0-2.1-1.7-3.8-3.8-3.8-2.1 0-3.8 1.7-3.8 3.8v1.5h-.8c-.8 0-1.5.7-1.5 1.5v7.5c0 .8.7 1.5 1.5 1.5h9c.8 0 1.5-.7 1.5-1.5v-7.5c.2-.8-.5-1.5-1.3-1.5ZM12 15.6c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5Zm2.3-6.7H9.6V7.4c0-1.3 1-2.3 2.3-2.3 1.3 0 2.3 1 2.3 2.3v1.5h.1Z"
        />
    </svg>
);
export default SvgLocked;
