import React from 'react';

const SvgArrowDown = ({color, width = 24, height = 24, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        {...props}
        style={{
            transform: 'rotate(-90deg)',
        }}
    >
        <g transform={`scale(${width / 24})`}>
            <path
                data-testid="arrow-down-icon-path"
                fill={color}
                d="M18 11.2H8.9L13.1 7 12 6l-6 6 6 6 1.1-1.1-4.2-4.2H18z"
            />
        </g>
    </svg>
);

export default SvgArrowDown;
