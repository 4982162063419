import React from 'react';

const SvgSparkles = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="m15.4 12 1.1-2.3 2.3-1.1-2.3-1.1-1.1-2.3-1.1 2.3L12 8.6l2.3 1.1 1.1 2.3Zm-4.2.8L9.8 9.7l-1.4 3.1-3.1 1.4 3.1 1.4 1.4 3.1 1.4-3.1 3.1-1.4-3.1-1.4Z"
        />
    </svg>
);

export default SvgSparkles;
