import React from 'react';

const SvgRoundedWarning = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <g
            clipPath="url(#round-warning_svg__a)"
            filter="url(#round-warning_svg__b)"
        >
            <path
                data-testid="rounded-warning-icon-path"
                fill={color}
                d="M11.25 14.25h1.5v1.5h-1.5v-1.5Zm0-6h1.5v4.5h-1.5v-4.5Zm.742-3.75C7.853 4.5 4.5 7.86 4.5 12c0 4.14 3.352 7.5 7.492 7.5 4.148 0 7.508-3.36 7.508-7.5 0-4.14-3.36-7.5-7.508-7.5ZM12 18c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6Z"
            />
        </g>
        <defs>
            <clipPath id="round-warning_svg__a">
                <path fill="#fff" d="M0 0h24v24H0z" />
            </clipPath>
            <filter
                id="round-warning_svg__b"
                width={25}
                height={25}
                x={-0.5}
                y={1.5}
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dy={2} />
                <feGaussianBlur stdDeviation={2.5} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                <feBlend
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_101_21"
                />
                <feBlend
                    in="SourceGraphic"
                    in2="effect1_dropShadow_101_21"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
);

export default SvgRoundedWarning;
