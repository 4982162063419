import React from 'react';

const SvgOpen = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path d="M17.3 17.2H6.79999V6.70001H12V5.20001H6.79999C5.99999 5.20001 5.29999 5.90001 5.29999 6.70001V17.2C5.29999 18 5.99999 18.7 6.79999 18.7H17.3C18.1 18.7 18.8 18 18.8 17.2V12H17.3V17.2ZM13.5 5.20001V6.70001H16.2L8.79999 14.1L9.89999 15.2L17.3 7.80002V10.5H18.8V5.30002H13.5V5.20001Z" fill={color}/>
    </svg>
);

export default SvgOpen;