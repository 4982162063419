import React from 'react';

const SvgParagraph = ({color, className = '', onClick, onMouseEnter, onMouseLeave}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        className={className}
        onClick={onClick}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
    >
        <path
            fill={color}
            d="M5 16v-1.5h9V16H5Zm0-3.75v-1.5h13.5v1.5H5ZM5 8.5V7h13.5v1.5H5Z"
        />
    </svg>
);

export default SvgParagraph;
