import React from 'react';

const SvgTextAlignRight = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M5.3 18.8h13.5v-1.5H5.3v1.5Zm4.5-3h9v-1.5h-9v1.5Zm-4.5-3h13.5v-1.5H5.3v1.5Zm4.5-3h9V8.3h-9v1.5ZM5.3 5.3v1.5h13.5V5.3H5.3Z"
        />
    </svg>
);

export default SvgTextAlignRight;
