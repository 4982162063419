import React from 'react';

const SvgUnderscore = ({color, ...props}) => (
    <svg 
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24" 
        viewBox="0 0 24 24" 
        fill="none"
        {...props}
    >
        <path d="M7.5 19.2998H16.5V20.7998H7.5V19.2998Z" fill={color}/>
    </svg>
);

export default SvgUnderscore;