import React from 'react';

const SvgExitArrow = ({color, width = 24, height = 24, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        {...props}
    >
        <g transform={`scale(${width / 24})`}>
            <path
                d="M15.75 8.25L14.7 9.3L16.65 11.25H9V12.75H16.65L14.7 14.7L15.75 15.75L19.5 12L15.75 8.25ZM6 17.25H12V18.75H6C5.175 18.75 4.5 18.075 4.5 17.25V6.75C4.5 5.925 5.175 5.25 6 5.25H12V6.75H6V17.25Z"
                fill={color}/>
        </g>
    </svg>);

export default SvgExitArrow;

