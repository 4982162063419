import React from 'react';

const SvgCopy = ({color = '', width = 24, height = 24, ...props}) => (<svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    {...props}
>
    <g clipPath="url(#clip0_9_1679)" transform={`scale(${width / 24})`}>
        <path
            d="M6.5 9H5V18C5 18.825 5.675 19.5 6.5 19.5H15.5V18H6.5V9ZM18 5H9.5C8.675 5 8 5.675 8 6.5V15C8 15.825 8.675 16.5 9.5 16.5H18C18.825 16.5 19.5 15.825 19.5 15V6.5C19.5 5.675 18.825 5 18 5ZM18 15H9.5V6.5H18V15Z"
            fill={color} />
    </g>
    <defs>
        <clipPath id="clip0_9_1679">
            <rect width="24" height="24" fill="white" />
        </clipPath>
    </defs>
</svg>);

export default SvgCopy;

