import React from 'react';

const SvgShopping = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M8 16.5c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5-.7-1.5-1.5-1.5Zm-4.5-12V6H5l2.7 5.7-1 1.8c-.1.2-.2.5-.2.7 0 .8.7 1.5 1.5 1.5h9v-1.4H8.3c-.1 0-.2-.1-.2-.2V14l.7-1.2h5.6c.6 0 1.1-.3 1.3-.8l2.7-4.9c.1-.1.1-.2.1-.4 0-.4-.3-.8-.8-.8h-11L6 4.4H3.5v.1Zm12 12c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5S17 18.8 17 18s-.7-1.5-1.5-1.5Z"
        />
    </svg>
);

export default SvgShopping;
