import React from 'react';

const SvgListNumbers = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M4 17.3h2.5v.6H5.2v1.3h1.3v.6H4v1.3h3.8V16H4v1.3Zm1.5-9.2h1.3V3H4.3v1.3h1.3v3.8h-.1ZM4 10.8h2.3L4 13.5v1.1h3.8v-1.3H5.5l2.3-2.7V9.5H4v1.3Zm5.5-5v1.5H20V5.8H9.5Zm0 12.5H20v-1.5H9.5v1.5Zm0-5.5H20v-1.5H9.5v1.5Z"
        />
    </svg>
);

export default SvgListNumbers;
