const getRoot = () => document.querySelector(':root');

const setValue = (key, value, root) => {
    const currentRoot = root || getRoot();
    currentRoot?.style.setProperty(key, value);
};

const getValue = (key, root) => {
    const currentRoot = root || getRoot();
    return getComputedStyle(currentRoot).getPropertyValue(key);
};

export const CSS_VARIABLES = {setValue, getValue};
