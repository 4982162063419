import React from 'react';

const SvgArrowsExpand = ({color, ...props}) => (
    <svg 
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
    >
        <path d="M5.25 18.75V12.75H6.75V16.2L16.2 6.75H12.75V5.25H18.75V11.25H17.25V7.8L7.8 17.25H11.25V18.75H5.25Z" fill={color}/>
    </svg>
);

export default SvgArrowsExpand;