export const isProduction = () => {
    try {
        return NODE_ENV === 'production';
    } catch (e) {
        console.error(e);
        return false;
    }
};

export const getRequestOrigin = ({isEmbedded, vbox}) => {
    try {
        const currentVbox = vbox || DEV_VBOX;

        const origin = !isEmbedded && !currentVbox
            ? window.location.origin
            : 'https://app.nimble.com';

        return currentVbox
            ? `https://${currentVbox.trim()}.devnimble.com`
            : origin;

    } catch (e) {
        console.error(e);
        return 'https://app.nimble.com';
    }
};