import React from 'react';

const SvgBold = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M14.4 11.8c.7-.5 1.2-1.3 1.2-2.1 0-1.7-1.3-3-3-3H8v10.5h5.3c1.6 0 2.8-1.3 2.8-2.8-.1-1.1-.7-2.1-1.7-2.6Zm-4.2-3.2h2.2c.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1h-2.2V8.6Zm2.6 6.8h-2.6v-2.2h2.6c.6 0 1.1.5 1.1 1.1 0 .6-.4 1.1-1.1 1.1Z"
        />
    </svg>
);

export default SvgBold;
