import React from 'react';

const SvgTagStar = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M18 12c0-.8.7-1.5 1.5-1.5v-3c0-.8-.7-1.5-1.5-1.5H6c-.8 0-1.5.7-1.5 1.5v3c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5v3c0 .8.7 1.5 1.5 1.5h12c.8 0 1.5-.7 1.5-1.5v-3c-.8 0-1.5-.6-1.5-1.5Zm-3.3 3.6L12 13.9l-2.7 1.7.8-3.1-2.5-2 3.2-.2 1.2-3 1.2 3 3.2.2-2.5 2 .8 3.1Z"
        />
    </svg>
);

export default SvgTagStar;
