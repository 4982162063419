import React from 'react';
import Icon from 'MAINAPPJS/components/icons/icon/icon';
import styles from '../styles/index.less';

const ErrorPage = ({title, message, withFormStyles}) => (
    <>
        <style>{styles.toString()}</style>
        <div className={`info-page ${withFormStyles ? '__with-form-styles' : ''}`}>
            <div className="info-page-gradient-box-wrapper">
                <div className="info-page-gradient-box __error">
                    <Icon iconValue="rounded-warning" color="#FFFFFF" />
                </div>
            </div>
            <h2 className="info-page-title">{title}</h2>
            {!!message && <p className="info-page-message">{message}</p>}
        </div>
    </>
);

export default ErrorPage;
