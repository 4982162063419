import React from 'react';

const SvgDataEnrichment = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <g clipPath="url(#data-enrichment_svg__a)">
            <path
                fill={color}
                d="M20.5 11.845 18.67 9.76 18.925 7l-2.707-.615L14.8 4l-2.55 1.095L9.7 4 8.283 6.385l-2.708.607.255 2.76L4 11.845l1.83 2.085-.255 2.768 2.708.614L9.7 19.698l2.55-1.103 2.55 1.095 1.418-2.385 2.707-.615-.255-2.76 1.83-2.085Zm-7.5 3.75h-1.5v-1.5H13v1.5Zm0-3h-1.5v-4.5H13v4.5Z"
            />
        </g>
        <defs>
            <clipPath id="data-enrichment_svg__a">
                <path fill="#fff" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default SvgDataEnrichment;
