import React from 'react';

const SvgBullets = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M5.9 10.9c-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1.6 0 1.1-.5 1.1-1.1 0-.6-.4-1.1-1.1-1.1Zm0-4.5c-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1.6 0 1.1-.5 1.1-1.1 0-.6-.4-1.1-1.1-1.1Zm0 9c-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1.6 0 1.1-.5 1.1-1.1 0-.6-.4-1.1-1.1-1.1Zm2.3 1.8h10.5v-1.5H8.2v1.5Zm0-4.4h10.5v-1.5H8.2v1.5Zm0-6v1.5h10.5V6.8H8.2Z"
        />
    </svg>
);

export default SvgBullets;
