import React from 'react';

const SvgEarth = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M12 4.5c-4.1 0-7.5 3.4-7.5 7.5s3.4 7.5 7.5 7.5 7.5-3.4 7.5-7.5-3.4-7.5-7.5-7.5Zm-.8 13.4C8.2 17.5 6 15 6 12c0-.5.1-.9.2-1.3l3.6 3.6v.7c0 .8.7 1.5 1.5 1.5v1.4h-.1Zm5.2-1.9c-.2-.6-.7-1-1.4-1h-.8v-2.2c0-.4-.3-.8-.8-.8H9v-1.5h1.5c.4 0 .8-.3.8-.8V8.2h1.5c.8 0 1.5-.7 1.5-1.5v-.3c2.2.9 3.8 3 3.8 5.6-.1 1.6-.7 3-1.7 4Z"
        />
    </svg>
);

export default SvgEarth;
