import React from 'react';
const SvgMarkAsRead = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="m14.463 18.5-3.188-3.187 1.05-1.05 2.138 2.137 4.237-4.237 1.05 1.05zM5.5 17q-.618 0-1.06-.44A1.45 1.45 0 0 1 4 15.5v-9q0-.619.44-1.06Q4.883 5 5.5 5h12q.62 0 1.06.44.44.442.44 1.06v3.262l-4.519 4.52-2.156-2.157-3.169 3.169L10.863 17zm6-5.25 6-3.75V6.5l-6 3.75-6-3.75V8z"
        />
    </svg>
);
export default SvgMarkAsRead;
