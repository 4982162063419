import React from 'react';

const SvgPin = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="m17.8 12.1 1-1.1L13 5.2l-1.1 1v2.1l-2.4 2.5H7.4l-1.1 1 2.4 2.4-3.5 3.5v1.1h1.1l3.5-3.5 2.4 2.4 1-1.1v-2.1l2.5-2.4h2.1Z"
        />
    </svg>
);

export default SvgPin;
