import React from 'react';

const SvgForward = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path fill={color} d="M12 9V6l6 6-6 6v-3H6V9h6Z" />
    </svg>
);

export default SvgForward;
