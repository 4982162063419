import React from 'react';

const SvgTracking = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M19.3 15.7c-1.6-7.3-2-8.9-2-9.1-.4-1.5-.9-2.1-2.1-2.1-1.2 0-2.1.9-2.1 2.1v2.1H11V6.6c0-1.2-.9-2.1-2.1-2.1-1.2 0-1.7.6-2.1 2.1-.1.2-.4 1.8-2 9.1-.4 1.6.7 3.8 3.1 3.8 1.7 0 3.2-1.4 3.2-3.2v-3.4h2.1v3.4c0 1.7 1.4 3.2 3.2 3.2 2.2 0 3.3-2.2 2.9-3.8ZM7.8 18.4c-1.2 0-2.1-.9-2.1-2.1 0-1.2.9-2.1 2.1-2.1 1.2 0 2.1.9 2.1 2.1 0 1.2-1 2.1-2.1 2.1Zm8.4 0c-1.2 0-2.1-.9-2.1-2.1 0-1.2.9-2.1 2.1-2.1 1.2 0 2.1.9 2.1 2.1 0 1.2-.9 2.1-2.1 2.1Z"
        />
    </svg>
);

export default SvgTracking;
