import {Utils} from 'COMMON/utilities';
import {COLORS} from 'COMMON/helpers/colors';
import {COMPONENTS_PROPS_KEYS} from 'WEBFORMS/config/components-props-config';
import {GLOBAL_CSS_VARIABLES} from 'WEBFORMS/config/global-styles-config';
import {FIELD_SIZES} from 'WEBFORMS/config/styling-fields-config';
import {FORM_CSS_VARIABLES} from 'WEBFORMS/form-css-variables';

export const MAX_FORM_WIDTH = 1000;

export const FORM_DESCRIPTION_FONT_SIZE_DELTA = 3;

export const MIN_FORM_DESCRIPTION_FONT_SIZE = 15;

export const getFormDescriptionFontSize = (fontSize) => {
    const descriptionFontSize = fontSize + FORM_DESCRIPTION_FONT_SIZE_DELTA;
    return descriptionFontSize >= MIN_FORM_DESCRIPTION_FONT_SIZE
        ? descriptionFontSize
        : MIN_FORM_DESCRIPTION_FONT_SIZE;
};

export const TOP_DESCRIPTIVE_SECTION_DIRECTIONS = Object.freeze({
    column: 'column',
    columnReverse: 'column-reverse',
});

export const DESCRIPTION_ALIGNMENTS = Object.freeze({
    left: 'left',
    center: 'center',
    right: 'right',
});

export const DESCRIPTION_MARGIN_BY_ALIGNMENTS = Object.freeze({
    [DESCRIPTION_ALIGNMENTS.left]: '0',
    [DESCRIPTION_ALIGNMENTS.center]: '0 auto',
    [DESCRIPTION_ALIGNMENTS.right]: '0 0 0 auto',
});

export const DESCRIPTION_ALIGNMENTS_LABELS = Object.freeze({
    [DESCRIPTION_ALIGNMENTS.left]: 'Left',
    [DESCRIPTION_ALIGNMENTS.center]: 'Center',
    [DESCRIPTION_ALIGNMENTS.right]: 'Right'
});

export const BUTTON_APPEARANCES = Object.freeze({
    primary: 'primary',
    secondary: 'secondary',
    ghost: 'ghost',
});

export const BUTTON_FORMS = Object.freeze({
    left: 'left',
    center: 'center',
    stretch: 'stretch',
    right: 'right',
});

export const BUTTON_DEFAULT_VALUES = {
    [COMPONENTS_PROPS_KEYS.buttonLabel]: 'Submit',
    [COMPONENTS_PROPS_KEYS.buttonType]: BUTTON_APPEARANCES.primary,
};

export const LOGO_ALIGNMENTS = Object.freeze({
    left: 'left',
    center: 'center',
    right: 'right',
});

export const LOGO_COLOR_VARIABLES = Object.freeze({
    fieldFontColor: GLOBAL_CSS_VARIABLES.fieldFontColor,
    accentColor: GLOBAL_CSS_VARIABLES.accentColor,
});

export const LOGO_DEFAULT_VALUES = {
    [COMPONENTS_PROPS_KEYS.logoAlignment]: LOGO_ALIGNMENTS.center,
    [COMPONENTS_PROPS_KEYS.logoColorVariable]: LOGO_COLOR_VARIABLES.accentColor,
};

export const THEMES = Object.freeze({
    light: 'lightTheme',
    dark: 'darkTheme',
});

export const FONT_SIZES = Object.freeze({
    [FIELD_SIZES.small]: {
        default: 11,
        min: 10,
        max: 16,
    },
    [FIELD_SIZES.medium]: {
        default: 13,
        min: 11,
        max: 18,
    },
    [FIELD_SIZES.large]: {
        default: 15,
        min: 12,
        max: 20,
    }
});

export const REGULAR_WEIGHT = '400';

export const BOLD_WEIGHT = '700';

export const DEFAULT_FONT = {
    family: 'Roboto',
    backup: 'sans-serif',
    style: 'normal',
    weight: REGULAR_WEIGHT,
    size: FONT_SIZES[FIELD_SIZES.small].default,
};

export const FONT_WEIGHTS = [
    {value: REGULAR_WEIGHT, isItalic: false},
    {value: BOLD_WEIGHT, isItalic: false},
    {value: REGULAR_WEIGHT, isItalic: true},
    {value: BOLD_WEIGHT, isItalic: true},
];

export const FONT_WEIGHTS_LABELS = {
    [REGULAR_WEIGHT]: 'Regular',
    [BOLD_WEIGHT]: 'Bold',
};

export const FONT_FAMILIES = Object.freeze([
    {family: 'Alegreya', backup: 'serif', hasItalicBold: true, hasItalicRegular: true},
    {family: 'Anek', backup: 'sans-serif', hasItalicBold: false, hasItalicRegular: false},
    {family: 'Cardo', backup: 'serif', hasItalicBold: false, hasItalicRegular: true},
    {family: 'Cormorant', backup: 'serif', hasItalicBold: true, hasItalicRegular: true},
    {family: 'DM Sans', backup: 'sans-serif', hasItalicBold: true, hasItalicRegular: true},
    {family: 'Eczar', backup: 'serif', hasItalicBold: false, hasItalicRegular: false},
    {family: 'Fira', backup: 'sans-serif', hasItalicBold: true, hasItalicRegular: true},
    {family: 'Inter', backup: 'sans-serif', hasItalicBold: false, hasItalicRegular: false},
    {family: 'Libre Baskerville', backup: 'serif', hasItalicBold: false, hasItalicRegular: true},
    {family: 'Libre Franklin', backup: 'sans-serif', hasItalicBold: true, hasItalicRegular: true},
    {family: 'Lora', backup: 'serif', hasItalicBold: true, hasItalicRegular: true},
    {family: 'Manrope', backup: 'sans-serif', hasItalicBold: false, hasItalicRegular: false},
    {family: 'PT Serif', backup: 'serif', hasItalicBold: true, hasItalicRegular: true},
    {family: 'Playfair Display', backup: 'serif', hasItalicBold: true, hasItalicRegular: true},
    {family: 'Poppins', backup: 'sans-serif', hasItalicBold: true, hasItalicRegular: true},
    {family: 'Quicksand', backup: 'sans-serif', hasItalicBold: false, hasItalicRegular: false},
    {family: 'Roboto', backup: 'sans-serif', hasItalicBold: true, hasItalicRegular: true},
    {family: 'Space Grotesk', backup: 'sans-serif', hasItalicBold: false, hasItalicRegular: false},
    {family: 'Syne', backup: 'sans-serif', hasItalicBold: false, hasItalicRegular: false},
    {family: 'Work Sans', backup: 'sans-serif', hasItalicBold: true, hasItalicRegular: true},
]);

export const FONT_FAMILIES_INDEXED_BY_FAMILY_NAME = Object.freeze(Utils.array.indexByProp({array: FONT_FAMILIES, prop: 'family'}));

export const getFontUrlToEmbed = ({family, weight, isItalic}) => {
    const baseUrl = 'https://fonts.googleapis.com/css2';
    const fontFamilyParam = `family=${encodeURIComponent(family)}`;
    const italicValue = isItalic ? 'ital,' : '';
    const fontStyleParam = isItalic ? '1,' : '';
    const fontWeightParam = `:${italicValue}wght@${fontStyleParam}${weight}`;
    const displayParam = 'display=swap';

    return `${baseUrl}?${fontFamilyParam}${fontWeightParam}&${displayParam}`;
};

export const colorizeBuilderPreviewAreaElements = (bgColor) => {
    FORM_CSS_VARIABLES.setValue('--background-point-color', COLORS.pickColorBasedOnBgColor({bgColor, lightColor: '#91A9DD', darkColor: '#2B498A'}));
    FORM_CSS_VARIABLES.setValue('--builder-preview-area-description-title-color', COLORS.pickColorBasedOnBgColor({bgColor, darkColor: '#41515C', lightColor: '#FFFFFF'}));
    FORM_CSS_VARIABLES.setValue('--builder-preview-area-description-subtitle-color', COLORS.pickColorBasedOnBgColor({bgColor, darkColor: '#72777E', lightColor: '#9EA6C9'}));
};

const OVERLAY = 'rgba(0,0,0,0.15)';
const BG_DARK = 'rgba(43,73,138,0.2)';
export const BG_LIGHT = 'rgba(255,255,255,0.3)';
export const OPACITY_15 = 0.15;
export const OPACITY_20 = 0.20;

export const COLOR_PRESETS = Object.freeze({
    standard: Object.freeze({
        lightTheme: Object.freeze({
            [GLOBAL_CSS_VARIABLES.fieldFontColor]: Object.freeze({bg: '#556083'}),
            [GLOBAL_CSS_VARIABLES.accentColor]: Object.freeze({bg: '#2291E3'}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorError]: Object.freeze({bg: '#F23D68', bottomCircleOpacity: OPACITY_15}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorPassive]: Object.freeze({bg: '#C7CDEA', bottomCircleOpacity: OPACITY_20}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorActive]: Object.freeze({bg: BG_LIGHT, border: '#2291E3'}),
            [GLOBAL_CSS_VARIABLES.formBgColor]: Object.freeze({bg: '#FFFFFF', withBorderDivider: true}),
            [GLOBAL_CSS_VARIABLES.bgColorBehindForm]: Object.freeze({bg: '#EDF1FF', withBorderDivider: true}),
        }),
        darkTheme: Object.freeze({
            [GLOBAL_CSS_VARIABLES.fieldFontColor]: Object.freeze({bg: '#C0CBEC'}),
            [GLOBAL_CSS_VARIABLES.accentColor]: Object.freeze({bg: '#2291E3', bottomCircleOverlay: OVERLAY}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorError]: Object.freeze({bg: '#F23D68', bottomCircleOpacity: OPACITY_20}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorPassive]: Object.freeze({bg: '#335BB1', bottomCircleOpacity: OPACITY_20}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorActive]: Object.freeze({bg: BG_DARK, border: '#2291E3'}),
            [GLOBAL_CSS_VARIABLES.formBgColor]: Object.freeze({bg: '#121929', withBorderDivider: true}),
            [GLOBAL_CSS_VARIABLES.bgColorBehindForm]: Object.freeze({bg: '#1C2F59', withBorderDivider: true}),
        }),
        label: 'Nimble Blue',
    }),
    blue: Object.freeze({
        lightTheme: Object.freeze({
            [GLOBAL_CSS_VARIABLES.fieldFontColor]: Object.freeze({bg: '#838EAF'}),
            [GLOBAL_CSS_VARIABLES.accentColor]: Object.freeze({bg: '#5783F3'}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorError]: Object.freeze({bg: '#FFA3A3', bottomCircleOpacity: OPACITY_15}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorPassive]: Object.freeze({bg: '#B1C7FF', bottomCircleOpacity: OPACITY_20}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorActive]: Object.freeze({bg: BG_LIGHT, border: '#5783F3'}),
            [GLOBAL_CSS_VARIABLES.formBgColor]: Object.freeze({bg: '#FFFFFF', withBorderDivider: true}),
            [GLOBAL_CSS_VARIABLES.bgColorBehindForm]: Object.freeze({bg: '#DBE5FF', withBorderDivider: true}),
        }),
        darkTheme: Object.freeze({
            [GLOBAL_CSS_VARIABLES.fieldFontColor]: Object.freeze({bg: '#838EAF'}),
            [GLOBAL_CSS_VARIABLES.accentColor]: Object.freeze({bg: '#5783F3', bottomCircleOverlay: OVERLAY}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorError]: Object.freeze({bg: '#FFA3A3', bottomCircleOpacity: OPACITY_20}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorPassive]: Object.freeze({bg: '#5171C3', bottomCircleOpacity: OPACITY_20}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorActive]: Object.freeze({bg: BG_DARK, border: '#5783F3'}),
            [GLOBAL_CSS_VARIABLES.formBgColor]: Object.freeze({bg: '#353E56', withBorderDivider: true}),
            [GLOBAL_CSS_VARIABLES.bgColorBehindForm]: Object.freeze({bg: '#25396D', withBorderDivider: true}),
        }),
        label: 'Blue',
    }),
    green: Object.freeze({
        lightTheme: Object.freeze({
            [GLOBAL_CSS_VARIABLES.fieldFontColor]: Object.freeze({bg: '#575F8B'}),
            [GLOBAL_CSS_VARIABLES.accentColor]: Object.freeze({bg: '#03B8AD'}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorError]: Object.freeze({bg: '#FF207E', bottomCircleOpacity: OPACITY_15}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorPassive]: Object.freeze({bg: '#85E7ED', bottomCircleOpacity: OPACITY_20}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorActive]: Object.freeze({bg: BG_LIGHT, border: '#03B8AD'}),
            [GLOBAL_CSS_VARIABLES.formBgColor]: Object.freeze({bg: '#FFFFFF', withBorderDivider: true}),
            [GLOBAL_CSS_VARIABLES.bgColorBehindForm]: Object.freeze({bg: '#C1FBFF', withBorderDivider: true}),
        }),
        darkTheme: Object.freeze({
            [GLOBAL_CSS_VARIABLES.fieldFontColor]: Object.freeze({bg: '#D0D3E5'}),
            [GLOBAL_CSS_VARIABLES.accentColor]: Object.freeze({bg: '#03B8AD', bottomCircleOverlay: OVERLAY}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorError]: Object.freeze({bg: '#FF207E', bottomCircleOpacity: OPACITY_20}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorPassive]: Object.freeze({bg: '#85E7ED', bottomCircleOpacity: OPACITY_20}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorActive]: Object.freeze({bg: BG_DARK, border: '#03B8AD'}),
            [GLOBAL_CSS_VARIABLES.formBgColor]: Object.freeze({bg: '#43464A', withBorderDivider: true}),
            [GLOBAL_CSS_VARIABLES.bgColorBehindForm]: Object.freeze({bg: '#296064', withBorderDivider: true}),
        }),
        label: 'Green',
    }),
    desert: Object.freeze({
        lightTheme: Object.freeze({
            [GLOBAL_CSS_VARIABLES.fieldFontColor]: Object.freeze({bg: '#9C9C9C'}),
            [GLOBAL_CSS_VARIABLES.accentColor]: Object.freeze({bg: '#F9CE60'}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorError]: Object.freeze({bg: '#E4063B', bottomCircleOpacity: OPACITY_15}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorPassive]: Object.freeze({bg: '#E9D9B0', bottomCircleOpacity: OPACITY_20}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorActive]: Object.freeze({bg: BG_LIGHT, border: '#F9CE60'}),
            [GLOBAL_CSS_VARIABLES.formBgColor]: Object.freeze({bg: '#FFFFFF', withBorderDivider: true}),
            [GLOBAL_CSS_VARIABLES.bgColorBehindForm]: Object.freeze({bg: '#FFEFC5', withBorderDivider: true}),
        }),
        darkTheme: Object.freeze({
            [GLOBAL_CSS_VARIABLES.fieldFontColor]: Object.freeze({bg: '#ABABAB'}),
            [GLOBAL_CSS_VARIABLES.accentColor]: Object.freeze({bg: '#F9CE60', bottomCircleOverlay: OVERLAY}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorError]: Object.freeze({bg: '#E4063B', bottomCircleOpacity: OPACITY_20}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorPassive]: Object.freeze({bg: '#A1812C', bottomCircleOpacity: OPACITY_20}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorActive]: Object.freeze({bg: BG_DARK, border: '#F9CE60'}),
            [GLOBAL_CSS_VARIABLES.formBgColor]: Object.freeze({bg: '#000000', withBorderDivider: true}),
            [GLOBAL_CSS_VARIABLES.bgColorBehindForm]: Object.freeze({bg: '#4F411C', withBorderDivider: true}),
        }),
        label: 'Desert',
    }),
    red: Object.freeze({
        lightTheme: Object.freeze({
            [GLOBAL_CSS_VARIABLES.fieldFontColor]: Object.freeze({bg: '#646C85'}),
            [GLOBAL_CSS_VARIABLES.accentColor]: Object.freeze({bg: '#F06969'}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorError]: Object.freeze({bg: '#D06780', bottomCircleOpacity: OPACITY_15}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorPassive]: Object.freeze({bg: '#EEF0F7', bottomCircleOpacity: OPACITY_20}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorActive]: Object.freeze({bg: BG_LIGHT, border: '#F06969'}),
            [GLOBAL_CSS_VARIABLES.formBgColor]: Object.freeze({bg: '#FFFFFF', withBorderDivider: true}),
            [GLOBAL_CSS_VARIABLES.bgColorBehindForm]: Object.freeze({bg: '#E8EDFF', withBorderDivider: true}),
        }),
        darkTheme: Object.freeze({
            [GLOBAL_CSS_VARIABLES.fieldFontColor]: Object.freeze({bg: '#9EA4A8'}),
            [GLOBAL_CSS_VARIABLES.accentColor]: Object.freeze({bg: '#F06969', bottomCircleOverlay: OVERLAY}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorError]: Object.freeze({bg: '#D06780', bottomCircleOpacity: OPACITY_20}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorPassive]: Object.freeze({bg: '#39393C', bottomCircleOpacity: OPACITY_20}),
            [GLOBAL_CSS_VARIABLES.fieldBorderColorActive]: Object.freeze({bg: BG_DARK, border: '#F06969'}),
            [GLOBAL_CSS_VARIABLES.formBgColor]: Object.freeze({bg: '#1A1B1F', withBorderDivider: true}),
            [GLOBAL_CSS_VARIABLES.bgColorBehindForm]: Object.freeze({bg: '#35353E', withBorderDivider: true}),
        }),
        label: 'Red',
    }),
});

export const parseRgb = ({rgbStr, defaultRgbStr = '255,255,255'}) => {
    const validRgbStr = rgbStr && rgbStr.split(',').length === 3
        ? rgbStr
        : defaultRgbStr;
    return validRgbStr.split(',').map((num) => Number(num.trim()));
};

export const parseSize = (size) => Number(size?.replace('px', '') || 0);

export const parseOpacity = (opacity) => Number(opacity?.replace('%', '') || 0);
