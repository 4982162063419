import React, {useEffect, useState} from 'react';
import {COLORS} from 'COMMON/helpers/colors';

const CheckboxField = ({name, id, label, predefinedValue, isRequired, disabled = false, accentColor}) => {
    const [checked, setChecked] = useState(predefinedValue ?? false);

    useEffect(() => {
        setChecked(!!predefinedValue);
    }, [predefinedValue]);

    const onInput = () => setChecked((prev) => !prev);

    const onKeyDown = (e) => {
        if (e.code === 'Space') {
            onInput();
        }
    };

    const getStyles = () => ({
        '--mark-color': COLORS.pickColorBasedOnBgColor({bgColor: accentColor})
    });

    const errorMsg = 'This field is required';
    const checkedClassName = checked ? '__checked' : '';
    const isInvalid = isRequired && !checked;
    const disabledClassName = disabled ? '__disabled' : '';
    const isEmpty = !checked;
    const tabIndex = disabled ? -1 : 1;

    return (
        // eslint-disable-next-line
        <label className={`checkbox-field field-box ${checkedClassName} ${disabledClassName}`} htmlFor={id}>
            <div className="checkbox-field-wrapper">
                <input
                    name={name}
                    id={id}
                    type="checkbox"
                    checked={checked}
                    disabled={disabled}
                    value={checked}
                    onInput={onInput}
                    aria-invalid={isInvalid}
                    data-invalid={isInvalid}
                    data-required={isRequired}
                    data-empty={isEmpty}
                />
                <span
                    tabIndex={tabIndex}
                    role="checkbox"
                    aria-checked={checked}
                    className={`checkbox-field-input ${checkedClassName}`}
                    onKeyDown={onKeyDown}
                    style={getStyles()}
                />
                <p className="checkbox-field__label" title={label}>
                    {label}
                </p>
                <p className="error-msg" title={errorMsg}>{errorMsg}</p>
            </div>
        </label>
    );
};

export default CheckboxField;
