import React, {forwardRef} from 'react';

export const WebFormBrandLogo = ({src}) => src && <img className="web-form-component-brand-logo" src={src} alt="Brand Logo"/>;

export const WebFormDescription = ({description}) => <div className="web-form-component-description">{description}</div>;

const WebForm = forwardRef(({children, onSubmit, isConfigurable = false, onInput, onKeyDown}, ref) => {

    const onSubmitHandler = (e) => {
        e.preventDefault();
        typeof onSubmit === 'function' && onSubmit(e);
    };

    const onInputHandler = (e) => {
        typeof onInput === 'function' && onInput(e);
    };

    const onKeyDownHandler = (e) => {
        typeof onKeyDown === 'function' && onKeyDown(e);
    };

    const configurableClassName = isConfigurable ? '__configurable' : '';

    return (
        // eslint-disable-next-line
        <form
            ref={ref}
            className={`web-form-component ${configurableClassName}`}
            onSubmit={onSubmitHandler}
            onInput={onInputHandler}
            onKeyDown={onKeyDownHandler}
        >
            {children}
        </form>
    );
});

WebForm.displayName = 'WebForm';

export default WebForm;
