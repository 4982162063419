import React from 'react';

const SvgRoundedComplete = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M12 4.5c-4.1 0-7.5 3.4-7.5 7.5s3.4 7.5 7.5 7.5 7.5-3.4 7.5-7.5-3.4-7.5-7.5-7.5Zm-1.5 11.3L6.7 12l1.1-1.1 2.7 2.7 5.7-5.7L17.3 9l-6.8 6.8Z"
        />
    </svg>
);

export default SvgRoundedComplete;
