import React from 'react';

const SvgSwitch = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M8.25 16.5c-1.25 0-2.313-.438-3.188-1.313C4.188 14.313 3.75 13.25 3.75 12c0-1.25.438-2.313 1.313-3.188C5.938 7.939 7 7.5 8.25 7.5h7.5c1.25 0 2.313.438 3.188 1.313.875.874 1.312 1.937 1.312 3.187 0 1.25-.438 2.313-1.313 3.188-.875.874-1.937 1.312-3.187 1.312h-7.5Zm0-1.125h7.5c.938 0 1.734-.328 2.39-.984.657-.657.985-1.454.985-2.391 0-.938-.328-1.734-.984-2.39-.657-.657-1.453-.985-2.391-.985h-7.5c-.938 0-1.734.328-2.39.984-.657.657-.985 1.454-.985 2.391 0 .938.328 1.734.984 2.39.657.657 1.454.985 2.391.985Zm-.019-1.481c.525 0 .972-.185 1.34-.553.37-.37.554-.816.554-1.341s-.184-.972-.553-1.34a1.825 1.825 0 0 0-1.34-.554c-.526 0-.973.185-1.341.553A1.826 1.826 0 0 0 6.338 12c0 .525.184.972.553 1.34.368.37.815.554 1.34.554Z"
        />
    </svg>
);

export default SvgSwitch;
