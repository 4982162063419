import React from 'react';

const SvgDropdown = ({color, className = '', onClick, onMouseEnter, onMouseLeave}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        className={className}
        onClick={onClick}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
    >
        <path
            fill={color}
            d="m12.5 14.125 3.375-3.375-1.069-1.05-2.306 2.306L10.194 9.7l-1.069 1.05 3.375 3.375Zm0 4.875a7.304 7.304 0 0 1-2.925-.59 7.575 7.575 0 0 1-2.381-1.604 7.574 7.574 0 0 1-1.603-2.381A7.303 7.303 0 0 1 5 11.5c0-1.037.197-2.012.59-2.925a7.574 7.574 0 0 1 1.604-2.381A7.574 7.574 0 0 1 9.575 4.59 7.303 7.303 0 0 1 12.5 4c1.037 0 2.012.197 2.925.59a7.574 7.574 0 0 1 2.381 1.604 7.575 7.575 0 0 1 1.603 2.381c.394.913.591 1.888.591 2.925a7.304 7.304 0 0 1-.59 2.925 7.575 7.575 0 0 1-1.604 2.381 7.575 7.575 0 0 1-2.381 1.603A7.304 7.304 0 0 1 12.5 19Zm0-1.5c1.675 0 3.094-.581 4.256-1.744C17.92 14.594 18.5 13.175 18.5 11.5c0-1.675-.581-3.094-1.744-4.256C15.594 6.08 14.175 5.5 12.5 5.5c-1.675 0-3.094.581-4.256 1.744C7.08 8.406 6.5 9.825 6.5 11.5c0 1.675.581 3.094 1.744 4.256C9.406 16.92 10.825 17.5 12.5 17.5Z"
        />
    </svg>
);

export default SvgDropdown;
