import React from 'react';

const SvgAutomation = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <g clipPath="url(#automation_svg__a)">
            <path
                fill={color}
                d="m17.698 9.489-2.7 1.26L13.25 9v-1.05l1.748-1.747 2.7 1.26a.565.565 0 0 0 .48-1.02l-2.94-1.373a.745.745 0 0 0-.848.15l-1.305 1.305a.731.731 0 0 0-.585-.3.752.752 0 0 0-.75.75v.75H9.365a2.258 2.258 0 0 0-2.115-1.5A2.247 2.247 0 0 0 5 8.476c0 .825.45 1.538 1.11 1.935l1.95 6.315h-.81c-.825 0-1.5.675-1.5 1.5v.75h9.75v-.75c0-.825-.675-1.5-1.5-1.5h-1.215L9.057 9.804c.128-.18.233-.368.308-.578h2.385v.75c0 .413.338.75.75.75.24 0 .45-.12.585-.3l1.305 1.305a.745.745 0 0 0 .848.15l2.94-1.372a.565.565 0 0 0-.48-1.02ZM7.25 9.226a.752.752 0 0 1-.75-.75c0-.412.338-.75.75-.75s.75.338.75.75c0 .413-.338.75-.75.75Zm3.832 7.5H9.627l-1.845-6h.075l3.225 6Z"
            />
        </g>
        <defs>
            <clipPath id="automation_svg__a">
                <path fill="#fff" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default SvgAutomation;
