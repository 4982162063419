const hexToRgb = (hex) => {
    const normalizedHex = hex.length === 4
        ? `${hex}${hex.slice(1, 4)}`
        : hex;
    const r = parseInt(normalizedHex.slice(1, 3), 16);
    const g = parseInt(normalizedHex.slice(3, 5), 16);
    const b = parseInt(normalizedHex.slice(5, 7), 16);

    return [r,g,b];
};

const hexToRgba = (hex, opacity = 1) => {
    const [r,g,b] = hexToRgb(hex);
    return `rgba(${r},${g},${b},${opacity})`;
};

const rgbToHex = (r, g, b) => {
    const toHex = (num) => {
        const hex = num.toString(16);
        return hex.length > 1 ? hex : `0${hex}`;
    };
    return '#' + toHex(r) + toHex(g) + toHex(b);
};

const isDark = (hexColor) => {
    const [r, g, b] = hexToRgb(hexColor);
    return ((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186;
};

const pickColorBasedOnBgColor = ({bgColor, lightColor = '#FFF', darkColor = '#000'}) => {
    return isDark(bgColor) ? darkColor : lightColor;
};

const colorNumberToHex = (colorNumber, defaultColor = '#00839D') => {
    const COLORS = {
        0: '#C67765',
        1: '#2D2D2D',
        2: '#A2004E',
        3: '#B60000',
        4: '#765D02',
        5: '#197B00',
        6: '#00839D',
        7: '#0C2DA4',
        8: '#750492',
        9: '#AFAFAF',
        10: '#E90D77',
        11: '#FF2626',
        12: '#FAC610',
        13: '#56CF37',
        14: '#14CAEE',
        15: '#2A56F2',
        16: '#CE39F3',
        17: '#D7D7D7',
        18: '#F284B9',
        19: '#E78484',
        20: '#E1CB7C',
        21: '#9EE48C',
        22: '#80DDEF',
        23: '#7E97ED',
        24: '#DA9EE9',
    };

    return COLORS[colorNumber] || defaultColor;
};

export const COLORS = {
    hexToRgb,
    hexToRgba,
    rgbToHex,
    pickColorBasedOnBgColor,
    colorNumberToHex,
};
