import React from 'react';

const Svg123 = ({color, width = 24, height = 24, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        {...props}
    >
        <g transform={`scale(${width / 24})`}>
            <path
                d="M7.125 14.5V11.125H6V10H8.25V14.5H7.125ZM9.75 14.5V12.625C9.75 12.4125 9.82188 12.2344 9.96563 12.0906C10.1094 11.9469 10.2875 11.875 10.5 11.875H12V11.125H9.75V10H12.375C12.5875 10 12.7656 10.0719 12.9094 10.2156C13.0531 10.3594 13.125 10.5375 13.125 10.75V11.875C13.125 12.0875 13.0531 12.2656 12.9094 12.4094C12.7656 12.5531 12.5875 12.625 12.375 12.625H10.875V13.375H13.125V14.5H9.75ZM14.25 14.5V13.375H16.5V12.625H15V11.875H16.5V11.125H14.25V10H16.875C17.0875 10 17.2656 10.0719 17.4094 10.2156C17.5531 10.3594 17.625 10.5375 17.625 10.75V13.75C17.625 13.9625 17.5531 14.1406 17.4094 14.2844C17.2656 14.4281 17.0875 14.5 16.875 14.5H14.25Z"
                fill={color} />
        </g>
    </svg>
);

export default Svg123;
