import React from 'react';
const SvgArchive = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M6.375 18.75c-.3 0-.563-.113-.787-.337a1.08 1.08 0 0 1-.338-.788V7.819c0-.188.019-.347.056-.478a.934.934 0 0 1 .207-.366l1.05-1.425c.1-.112.215-.19.346-.234.132-.044.285-.066.46-.066h9.262c.175 0 .325.022.45.066a.808.808 0 0 1 .338.234l1.069 1.425c.1.113.168.234.206.366.037.13.056.29.056.478v9.806c0 .3-.113.563-.337.788a1.08 1.08 0 0 1-.788.337H6.375Zm.319-11.512h10.594l-.675-.863H7.369l-.675.862Zm5.306 8.7 2.925-2.926-.75-.75-1.613 1.613v-3.769h-1.124v3.769l-1.613-1.613-.75.75L12 15.938Z"
        />
    </svg>
);

export default SvgArchive;
