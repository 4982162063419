import React from 'react';

const SvgWarningOutline = ({color, width = 24, height = 24, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        {...props}
    >
        <path transform={`scale(${width / 24})`}
            d="M3.75 18.125L12 3.875L20.25 18.125H3.75ZM11.5125 13.85H12.6375V9.65H11.5125V13.85ZM12.075 15.9313C12.2375 15.9313 12.3719 15.8781 12.4781 15.7719C12.5844 15.6656 12.6375 15.5313 12.6375 15.3688C12.6375 15.2063 12.5844 15.0719 12.4781 14.9656C12.3719 14.8594 12.2375 14.8063 12.075 14.8063C11.9125 14.8063 11.7781 14.8594 11.6719 14.9656C11.5656 15.0719 11.5125 15.2063 11.5125 15.3688C11.5125 15.5313 11.5656 15.6656 11.6719 15.7719C11.7781 15.8781 11.9125 15.9313 12.075 15.9313ZM5.7 17H18.3L12 6.125L5.7 17Z"
            fill={color} />
    </svg>

);

export default SvgWarningOutline;
