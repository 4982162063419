import React from 'react';

const SvgCancel = ({color, width = 24, height = 24, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        {...props}
    >
        <g transform={`scale(${width / 24})`}>
            <path d="M9.1875 15.6L12 12.7875L14.8125 15.6L15.6 14.8125L12.7875 12L15.6 9.1875L14.8125 8.4L12 11.2125L9.1875 8.4L8.4 9.1875L11.2125 12L8.4 14.8125L9.1875 15.6ZM12 19.5C10.975 19.5 10.0063 19.3031 9.09375 18.9094C8.18125 18.5156 7.38438 17.9781 6.70312 17.2969C6.02188 16.6156 5.48438 15.8188 5.09063 14.9062C4.69688 13.9938 4.5 13.025 4.5 12C4.5 10.9625 4.69688 9.9875 5.09063 9.075C5.48438 8.1625 6.02188 7.36875 6.70312 6.69375C7.38438 6.01875 8.18125 5.48438 9.09375 5.09063C10.0063 4.69688 10.975 4.5 12 4.5C13.0375 4.5 14.0125 4.69688 14.925 5.09063C15.8375 5.48438 16.6313 6.01875 17.3063 6.69375C17.9813 7.36875 18.5156 8.1625 18.9094 9.075C19.3031 9.9875 19.5 10.9625 19.5 12C19.5 13.025 19.3031 13.9938 18.9094 14.9062C18.5156 15.8188 17.9813 16.6156 17.3063 17.2969C16.6313 17.9781 15.8375 18.5156 14.925 18.9094C14.0125 19.3031 13.0375 19.5 12 19.5Z" fill={color}/>
        </g>
    </svg>
);

export default SvgCancel;
