import React from 'react';

const SvgHeart = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <g clipPath="url(#heart_svg__a)">
            <path
                fill={color}
                d="m11.5 18.762-1.088-.99C6.55 14.27 4 11.96 4 9.126A4.085 4.085 0 0 1 8.125 5c1.305 0 2.557.607 3.375 1.567A4.491 4.491 0 0 1 14.875 5 4.085 4.085 0 0 1 19 9.125c0 2.835-2.55 5.145-6.412 8.655l-1.088.982Z"
            />
        </g>
        <defs>
            <clipPath id="heart_svg__a">
                <path fill="#fff" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default SvgHeart;
