import React from 'react';

const SvgLink = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M5.9 12c0-1.3 1-2.3 2.3-2.3h3V8.3h-3c-2.1 0-3.8 1.7-3.8 3.8 0 2.1 1.7 3.8 3.8 3.8h3v-1.4h-3C7 14.3 5.9 13.3 5.9 12Zm3.1.8h6v-1.5H9v1.5Zm6.8-4.6h-3v1.4h3c1.3 0 2.3 1 2.3 2.3 0 1.3-1 2.3-2.3 2.3h-3v1.4h3c2.1 0 3.8-1.7 3.8-3.8 0-2.1-1.8-3.6-3.8-3.6Z"
        />
    </svg>
);

export default SvgLink;
