import React from 'react';

const SvgFile = ({color, width = 24, height = 24, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            transform={`scale(${width / 24})`}
            data-testid="file-icon"
            d="M6.697 6.697c1.626-1.627 4.172-1.627 5.798 0l5.586 5.586c1.202 1.202 1.202 3.04 0 4.243-1.202 1.202-3.04 1.202-4.242 0L9.313 12a1.9 1.9 0 0 1 0-2.687c.778-.778 1.91-.778 2.687 0l3.96 3.96-1.06 1.06-4.031-4.03c-.283-.283-.849.283-.566.566l4.525 4.525c.566.566 1.556.566 2.122 0s.565-1.556 0-2.121l-5.516-5.516c-.99-.99-2.687-.99-3.677 0s-.99 2.687 0 3.677l5.02 5.02-1.06 1.061-5.02-5.02c-1.627-1.626-1.627-4.172 0-5.798"
        />
    </svg>
);

export default SvgFile;
