import React from 'react';

const SvgBirthday = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M12 6.2c.8 0 1.5-.7 1.5-1.5 0-.3-.1-.5-.2-.8L12 1.7 10.7 4c-.1.2-.2.5-.2.8 0 .8.7 1.4 1.5 1.4Zm3.5 7.5-.8-.8-.8.8c-1 1-2.7 1-3.7 0l-.8-.8-.8.8c-.5.5-1.1.8-1.8.8-.5 0-1.1-.2-1.5-.5v3.5c0 .4.3.8.8.8h12c.4 0 .8-.3.8-.8V14c-.4.3-.9.5-1.5.5-.8 0-1.5-.3-1.9-.8Zm1-5.2h-3.7V7h-1.5v1.5H7.5c-1.2 0-2.2 1-2.2 2.2v1.2c0 .8.7 1.5 1.5 1.5.4 0 .8-.2 1-.4l1.6-1.6L11 13c.6.6 1.5.6 2.1 0l1.6-1.6 1.6 1.6c.3.3.6.4 1 .4.8 0 1.5-.7 1.5-1.5v-1.2c0-1.2-1.1-2.2-2.3-2.2Z"
        />
    </svg>
);

export default SvgBirthday;
