import React from 'react';

const SvgContact = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M12 12c1.7 0 3-1.3 3-3s-1.3-3-3-3-3 1.3-3 3 1.3 3 3 3Zm0 1.5c-2 0-6 1-6 3V18h12v-1.5c0-2-4-3-6-3Z"
        />
    </svg>
);

export default SvgContact;
