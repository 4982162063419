import React from 'react';

const SvgPlus = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M17.2 12.8h-4.5v4.5h-1.5v-4.5H6.7v-1.5h4.5V6.8h1.5v4.5h4.5v1.5Z"
        />
    </svg>
);
export default SvgPlus;
