import React from 'react';
import {COLORS} from 'COMMON/helpers/colors';
import {BUTTON_APPEARANCES, BUTTON_FORMS} from 'WEBFORMS/config';

const SubmitButton = ({label, type = BUTTON_APPEARANCES.primary, disabled, form, accentColor, isLoading}) => {
    const getStyles = () => ({
        '--submit-btn-text-color': type === BUTTON_APPEARANCES.primary
            ? COLORS.pickColorBasedOnBgColor({bgColor: accentColor})
            : accentColor
    });

    const typeClassName = `__${type}`;
    const loadingClassName = isLoading ? '__loading' : '';
    const tabIndex = disabled ? -1 : 1;

    const positionClassName = {
        [BUTTON_FORMS.left]: '__left',
        [BUTTON_FORMS.center]: '__center',
        [BUTTON_FORMS.right]: '__right',
        [BUTTON_FORMS.stretch]: '__stretch',
    }[form];

    return (
        <div className={`submit-button-wrapper ${positionClassName}`}>
            <button
                type="submit"
                className={`submit-button ${typeClassName} ${loadingClassName}`}
                style={getStyles()}
                aria-disabled={disabled}
                data-disabled={disabled}
                tabIndex={tabIndex}
            >
                <span className="submit-button__label">{label}</span>
            </button>
        </div>
    );
};

export default SubmitButton;
