import React from 'react';

const SvgCalendar = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M9.8 10.44H8.3v1.5h1.5v-1.5Zm3 0h-1.5v1.5h1.5v-1.5Zm3 0h-1.5v1.5h1.5v-1.5Zm1.5-5.2h-.8v-1.5H15v1.5H9v-1.5H7.5v1.5h-.7c-.8 0-1.5.7-1.5 1.5v10.5c0 .8.7 1.5 1.5 1.5h10.5c.8 0 1.5-.7 1.5-1.5V6.74c0-.8-.7-1.5-1.5-1.5Zm0 12H6.8v-8.2h10.5v8.2Z"
        />
    </svg>
);

export default SvgCalendar;
