import {useLayoutEffect} from 'react';

/**
 * A custom React hook that uses the ResizeObserver API to monitor changes
 * in the dimensions of a referenced DOM element. It executes a callback whenever
 * the element is resized.
 *
 * @param {React.RefObject<HTMLElement>} ref - A React reference object pointing to the DOM element to observe.
 * @param {(data: {width: number, height: number, element: HTMLElement}) => void} callback - A callback function invoked when the observed element is resized. Receives an object containing the width, height, and the observed element itself.
 * @param {React.DependencyList} deps - An array of dependencies that determine when to recreate the ResizeObserver.
 *
 * @example
 * // Usage in a React component
 * const ref = useRef();
 * useResizeObserver(ref, (size) => {
 *   console.log(size.width, size.height);
 * }, []);
 *
 * return <div ref={ref}>Resizable Content</div>;
 */
export const useResizeObserver = (ref, callback, deps) => {
    useLayoutEffect(() => {
        if (!ref.current) return;
        const resizeObserver = new ResizeObserver(() => {
            if (!ref.current) return;
            const width = ref.current.offsetWidth;
            const height = ref.current.offsetHeight;
            typeof callback === 'function' && callback({width, height, element: ref.current});
        });
        resizeObserver.observe(ref.current);
        return () => {
            resizeObserver.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};
