import React from 'react';

const SvgNavigate = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M12 11.2c-.5 0-.8.4-.8.8s.4.8.8.8.8-.4.8-.8-.3-.8-.8-.8Zm0-6.7c-4.1 0-7.5 3.4-7.5 7.5s3.4 7.5 7.5 7.5 7.5-3.4 7.5-7.5-3.4-7.5-7.5-7.5Zm1.6 9.1-6.1 2.9 2.9-6.1 6.1-2.9-2.9 6.1Z"
        />
    </svg>
);

export default SvgNavigate;
