import React from 'react';
import PoweredByNimbleLabelImage from 'WEBFORMS/fields/components/powered-by-nimble-label/label-image';

const PoweredByNimbleLabel = ({alignment, color, isClickable = false}) => {
    const onClick = () => {
        if (isClickable) {
            window.open('https://www.nimble.com/', '_blank');
        }
    };

    return (
        <div
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            className={`powered-by-nimble-label __${alignment}`}
            onClick={onClick}
        >
            <PoweredByNimbleLabelImage color={color} />
        </div>
    );
};

export default PoweredByNimbleLabel;
